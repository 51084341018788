<template>
    <div class="mod-body bg-content 9999" ref="orderBooks">
        <dl>
            <dt class="bg-title text-label" ref="orderBooksTitle" >
                <span class="price">{{ $t('futures.depth_price') }} ({{ symbol.metadata.quote_symbol
                    }})</span>
                <span class="amount">{{ $t('futures.depth_volume') }} ({{ isExchange ? symbol.metadata.base_symbol: $t('futures.label_unit')
                    }})</span>
                <span class="total">{{ $t('futures.depth_total_volume') }} ({{ isExchange ? symbol.metadata.base_symbol: $t('futures.label_unit')
                    }})</span>
            </dt>
            <dd class="now-pric text-content" :style="{top: nowPriceTop +'px'}">
                <div class="now-price" ref="orderBooksPrice">
                    <dl>
                        <dt v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price }}</dt>
                    </dl>
                </div>
                <div class="asks" :style="{bottom:30 + distanceHeight +'px'}">
                    <p  v-for="order in askslist"
                        v-bind:key="order.key" @click="onPriceSelected(order.price)" :style="{height: scrollerHeight,'line-height': scrollerHeight}">
                        <span class="price color-down">{{ order.price }}</span>
                        <span class="amount">{{ order.volume }}</span>
                        <span class="total">{{ order.total }}</span>
                        <span class="progress-bar ask-bar" :style="'width:' + order.width"></span>
                    </p>
                </div>
                <div class="bids" :style="{top: 30 + distanceHeight +'px'}">
                    <p  v-for="order in bidslist"
                        v-bind:key="order.key" @click="onPriceSelected(order.price)" :style="{height: scrollerHeight,'line-height': scrollerHeight}">
                        <span class="price color-up">{{ order.price }}</span>
                        <span class="amount">{{ order.volume }}</span>
                        <span class="total">{{ order.total }}</span>
                        <span class="progress-bar bid-bar" :style="'width:' + order.width"></span>
                    </p>
                </div>
            </dd>
        </dl>
    </div>
</template>

<style scoped>
.order-books .mod-body dl {
    position: relative;
    height: 100%;
}
.order-books .mod-body dl dt {
    padding: 0 0.3rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    background: #15181C;
}
.order-books{
    height: 100%;
    width: 100%;
}
.order-books .mod-body dl dd > div.asks {
    bottom: 28px;
}
.order-books .mod-body dl dd > div.bids {
    top: 30px;
}
.order-books .mod-body dl .now-pric p{
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 0.3rem;
}
.order-books .mod-body {
    height: 100%;
    width: 100%;
}
.order-books .mod-body dl dd span {
    font-size: 12px;
}
.order-books .mod-body dl .bg-title span {
    white-space: pre-wrap;
    align-items: flex-start;
    word-break: normal;
}  
.order-books .mod-body dl .bg-title span.amount{
    padding-right: 5px;
} 
.content-top>div.right {
    height: initial;
}
.order-books .mod-body dl p .ask-bar {
    background: rgba(246, 70, 93, .15);
}
.order-books .mod-body dl p .bid-bar {
    background: rgba(14, 203, 129, .15);
}
</style>

<script>
export default {
    props: ['symbol','order_books','showTradeEvent','robotsNumber','isExchange'],
    data() {
        return {
            askslist:[],
            bidslist:[],
            scrollerHeight:"",
            distanceHeight:"",
            nowPriceTop:""
        };
    },

    mounted() {
        this.getRobotNumber();
        // 监听浏览器窗口大小改变
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.getRobotNumber();
            })
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeHandler);
    },
    watch: {
        //手机端模式兼容 由于在手机端最开始显示的是折线图 这时计算的高度是0 因此到显示机器人页面时要重新计算
        showTradeEvent:{
            handler: function(val){
                this.getRobotNumber();
            } 
        },
        order_books:{
            handler: function(val){
                Vue.nextTick(() => {
                    this.getRobotNumber();
                });
            },
            immediate: true,
            deep: true
        },
        '$i18n.locale': function (newVal, oldVal) {
            this.getRobotNumber();
        }
    },

    methods: {
        resizeHandler() {
            this.getRobotNumber();
        },
        onPriceSelected(price){
            this.$emit('clickEvent', price);
        },
        getRobotNumber(){
            let totalHeight = this.$refs.orderBooks ? this.$refs.orderBooks.offsetHeight : "";
            let titleHeight = this.$refs.orderBooksTitle ? this.$refs.orderBooksTitle.offsetHeight : "";
            let priceHeight = this.$refs.orderBooksPrice ? this.$refs.orderBooksPrice.offsetHeight : "";
            let contentHeight=(totalHeight-titleHeight-priceHeight)/2;
            let robotNumber = contentHeight/34;
            let newNumber ="";
            //小数部位
            var decimal=robotNumber.toString().replace(/\d+\.(\d*)/,"$1");
            newNumber =Math.trunc(robotNumber)+1;
            if(this.robotsNumber){
               newNumber = this.robotsNumber;
            }
            this.scrollerHeight=contentHeight/newNumber+'px';
            this.askslist =this.order_books.asks.length > newNumber?this.order_books.asks.slice(0,newNumber):this.order_books.asks;
            this.bidslist =this.order_books.bids.length > newNumber?this.order_books.bids.slice(0,newNumber):this.order_books.bids;
            this.nowPriceTop = titleHeight + contentHeight;
        },
    }
};
</script>