<template>
    <div class="w-100 text-center prompted-symbol">
        <div class="token-icon-wrapper d-flex justify-content-center">
            <token-icon :symbol="symbol" />
        </div>
        <div class="symbol-content">
            <h2>{{ symbol.metadata.baseSymbol || symbol.metadata.name }}</h2>
            <div class="price">${{ symbol.price }}</div>
            <div :class="symbol.up ? 'up' : 'down'">{{ numShortener(symbol.price_change_pct.split('%')[0]) }}%</div>
        </div>
    </div>
</template>

<script>
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['symbol'],

    methods: {
        numShortener: function (num) {
            let result;
            if (!isNaN(num)) {
                num = Number(num);
                result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
            } else {
                result = num;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.prompted-symbol {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    background: none;
    display: flex;
    align-items: center;
}
.symbol-content {
    background: none;
    color: #161616;
    height: 1.5rem;
    line-height: 1.5rem;
    display: flex;
    width: 100%;
    align-items: center;
}
.symbol-content .price {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #242428;
    background: none;
    width: 33%;
    text-align: left;
}
.symbol-content h2 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #242428;
    margin: 0;
    width: 33%;
}
.token-icon-wrapper {
    height: 2rem;
    line-height: 2rem;
    width: 2rem;
    margin-right: .5rem;
}
.symbol-content h2 {
    font-size: 1.25rem;
    min-width: 54px;
    text-align: left;
}

.symbol-content .up {
    color: var(--up);
}
/* .symbol-content .slide-price {
    color: #acafbe;
} */
.symbol-content .up::before {
    content: url('@/assets/images/rising-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
.symbol-content .down {
    color: var(--down);
}
.symbol-content .down::before {
    content: url('@/assets/images/falling-arrow.png');
    display: inline-block;
    vertical-align: sub;
    height: 0.7rem;
}
@media (max-width: 768px) {
    .symbol-content h2 {
        font-size: 1rem;
    }
}
</style>