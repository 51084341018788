<template>
    <section class="page startup-page">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title-box">
                            <h1>{{ $t('general.startup') }}</h1>
                            <div class="title-des media-display">{{ $t('home.guide_verify_identity_long') }}</div>
                            <div class="step-img-box media-display">
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupFirst.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupSecond.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupThird.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/images/v4.2/startupLogo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">
            <ul class="page-top-nav">
                <li class="top-nav-item">
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li>
                <li class="top-nav-item">
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li class="active top-nav-item">
                    <a href="javascript:;">{{ $t('startup.title_orders') }}</a>
                </li>
            </ul>

            <table class="table compact">
                <thead>
                    <tr>
                        <th>{{ $t('startup.orders_token') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_start_time') }}</th>
                        <th class="d-none d-md-table-cell">{{ $t('startup.orders_end_time') }}</th>
                        <th>{{ $t('startup.label_offer_price') }}</th>
                        <th>{{ $t('startup.orders_num_subscribed') }}</th>
                        <!-- <th>{{ $t('startup.orders_status') }}</th> -->
                        <th>{{ $t('startup.orders_num_granted') }}</th>
                        <th>{{ $t('startup.orders_time') }}</th>
                    </tr>
                </thead>
                <tbody v-if="orders">
                    <tr v-for="order in orders" :key="order.uid">
                        <td>
                            <div class="d-flex align-items-center">
                                <div v-if="order.svgIcon" class="pe-2">
                                    <startup-icon :token="order" />
                                </div>
                                <div>{{ order.token }}</div>
                            </div>
                        </td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.startTime).formatDateTime() }}</td>
                        <td class="d-none d-md-table-cell">{{ new Date(order.endTime).formatDateTime() }}</td>
                        <td>{{ order.offerPrice }}</td>
                        <td>
                            {{ order.numSubscribed }}
                            <span v-if="order.status === 1" class="badge bg-primary">
                                {{ $t('startup.status_subscribed') }}
                            </span>
                            <span v-else-if="order.status === 2" class="badge bg-primary">
                                {{ $t('startup.status_processing') }}
                            </span>
                            <span v-else-if="order.status === 3" class="text-muted small">
                                {{ $t('startup.status_granted') }}
                            </span>
                            <span v-else class="text-muted small">
                                {{ $t('startup.status_other') }}
                            </span>
                        </td>
                        <!-- <td>{{ order.status }}</td> -->
                        <td>{{ order.status === 3 ? order.numGranted : '--' }}</td>
                        <td>
                            <span class="d-none d-md-block">{{ new Date(order.timeCreated).formatDateTime() }}</span>
                            <span class="d-block d-md-none">{{ new Date(order.timeCreated).formatDate() }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <loading-indicator v-if="!orders" />
            <div v-else-if="orders.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </section>
    </section>
</template>
<style scoped>
.page-top {
    background: linear-gradient(to bottom,#EFF5FF,#E4EFFF);
}
.page-top .container {
    margin: 43px auto 21px;
}
.page-top .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col .title-box {
    flex: 1;
}
.line {
    margin: 0;
    width: 27px;
    background: #7F8490;
}
.media-display {
    visibility: hidden;
}
.page-top .col img {
    width: 177px;
    height: 150px;
}
.title-box .step-img-box .step-img {
    width: 24px;
    height: 24px;
}
.title-box .step-img-box {
    display: flex;
    width: 100%;
    align-items: center;
}
.step-img-box .line {
    margin-top: -13px;
}
.img-box {
    text-align: center;
}
.img-box .step-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: #7F8490;
    line-height: 14px;
    word-break: keep-all;
}
.page-top .col h1 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #000922;
    line-height: 50px;
}
.page-top-nav {
    margin: 40px 0 30px;
    border: none;
}
.page-top-nav .top-nav-item {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    margin-right: 20px;
}
.page-top-nav .top-nav-item a {
    color: #000;
    font-size: 18px;
}
.page-top-nav .top-nav-item.active {
    color: #1673f9;
    border-bottom: 2px solid #1673f9;
}
.page-top-nav li.active a {
    color: #1673f9;
    font-size: 20px;
    font-weight: 600;
}
.table.compact {
    --bs-table-bg: #fff;
}
@media (max-width: 768px) {
    .page {
        background: #FAFAFA;
    }
    .page-top,.pro_layout {
        background-color: #fff;
    }
    .title-box h1 {
        margin: 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #000922;
        line-height: 24px;
    }
    .title-des {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #1673F9;
        line-height: 16px;
        margin: 8px 0 22px;
    }
    .media-display {
        visibility: visible;
    }
    .page-top-nav {
        margin: 0 0 23px;
    }
    .page-top-nav .active {
        border-bottom: 2px solid #1673F9;
    }
    .page-top .col img {
        transform: scale(.93);
    }
}
</style>
<script>
import StartupIcon from '../Components/_StartupIcon.vue';

export default {
    components: { StartupIcon },
    data() {
        return { orders: null };
    },

    created() {
        this.getOrdersAsync();
    },

    methods: {
        getOrdersAsync: async function () {
            const json = await $.callGetApi(this, '/api/v1/startuporder');
            if (json && json.errcode === 0) {
                this.orders = json.data;
            }
        },
        padZero:function (num) {
            return num >10 ? num : `0${num}`
        },
        getRemainingTime(startTimestamp, endTimestamp) {
                    // 计算时间差
                    const timeDiff = endTimestamp - startTimestamp;
                    
                    // 计算剩余天数
                    const days = this.padZero(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));
                    
                    // 计算剩余小时数
                    const hours = this.padZero(Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                    
                    // 计算剩余分钟数
                    const minutes = this.padZero(Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)));
                    
                    // 计算剩余秒数
                    const seconds = this.padZero(Math.floor((timeDiff % (1000 * 60)) / 1000));
                    
                    // 返回剩余时间
                    return `距结束： ${days} 天${hours}:${minutes}:${seconds}`
        },
        numSubscribed(status){
            const order = {
                1:  this.$t('startup.status_subscribed'),
                2:  this.$t('startup.status_processing') ,
                3:  this.$t('startup.status_granted'),
            }
            if (order[status]) {
                return order[status]
            }
            return this.$t('startup.status_other')

        }
    }
};
</script>