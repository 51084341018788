<template>
    <div class="card settings-panel">
        <div class="card-header">系统公告</div>
        <div class="card-body">
            <div v-if="notices">
                <div v-if="notices.length === 0" class="help-block">暂无公告</div>
                <div v-for="notice in notices" v-bind:key="notice.id">
                    <router-link v-bind:to="{name: 'notice', params: { id: notice.id }}">{{ notice.title }}</router-link>
                </div>
            </div>
            <loading-indicator-sm v-else />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return { notices: null }
        },

        created() {
            $.callGetApi(this, '/api/v1/notices').then(json => {
                if (json && json.errcode === 0) {
                    this.notices = json.data;
                }
            }).catch(err => {
                console.error('Failed to read notices.');
            });
        }
    }
</script>