<template>
    <div class="intro">
        <div class="pc-container">
            <div class="container">
                <div class="tab-intro">
                    <div class="left">
                        <img src="@/assets/images/v4.2/indexIcon2.png" alt="" />
                    </div>
                    <div class="right">
                        <div class="title">{{ $t('v42.explore_title', { platform: sysconfig.name }) }}</div>
                        <div class="content">
                            <div class="switch-top">
                                <a href="javascript:;" @click="introSwitch = 1" class="link" :class="{ active: introSwitch === 1 }">{{ $t('v42.label_spot') }}</a>
                                <a href="javascript:;" @click="introSwitch = 2" class="link" :class="{ active: introSwitch === 2 }">{{ $t('v42.label_futures') }}</a>
                                <!-- <a href="javascript:;" @click="introSwitch = 3" class="link" :class="introSwitch === 3?'active':''">{{ $t('home.intro_v10_icon12')}}</a> -->
                            </div>
                            <div class="switch-content">
                                <p>{{ $t('v42.explore_desp') }}</p>
                                <!-- <p v-if="introSwitch === 2">{{ $t('home.intro_v10_icon4') }}</p> -->
                                <!-- <p v-if="introSwitch === 3">{{ $t('home.intro_v10_icon5')}}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="news-title">
                    <h3>{{ $t('v42.news_title') }}</h3>
                    <p>{{ $t('v42.news_desp') }}</p>
                </div>
                <div class="news-intro">
                    <div class="list-box">
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon3.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.news_title1') }}</p>
                                <p class="p">{{ $t('v42.news_desp1') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon4.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.news_title2') }}</p>
                                <p class="p">{{ $t('v42.news_desp2') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon5.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.news_title3') }}</p>
                                <p class="p">{{ $t('v42.news_desp3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="otc-title">
                    <h3>{{ $t('v42.security_title', { platform: sysconfig.name }) }}</h3>
                    <p>{{ $t('v42.security_desp') }}</p>
                </div>
                <div class="otc-intro">
                    <div class="list-box">
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon7.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t1') }}</p>
                                <p class="p">{{ $t('v42.security_desp1') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon6.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t2') }}</p>
                                <p class="p">{{ $t('v42.security_desp2') }}</p>
                            </div>
                        </div>
                        <div class="list">
                            <div class="img"><img src="@/assets/images/v4.2/indexIcon8.png" alt="" /></div>
                            <div class="desp">
                                <p class="t">{{ $t('v42.security_t3') }}</p>
                                <p class="p">{{ $t('v42.security_desp3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="help-title">{{ $t('home.intro_title') }}</div>
                <div class="help-intro">
                    <div class="li-box">
                        <i class="icon icon1"></i>
                        <p class="t">{{ $t('home.intro_v9_icon15') }}</p>
                        <p class="p">{{ $t('home.intro_v9_icon16') }}</p>
                    </div>
                    <div class="li-box">
                        <i class="icon icon2"></i>
                        <p class="t">{{ $t('home.intro_v9_icon17', { 'platform': $tigerConfig.shortName }) }}</p>
                        <p class="p">{{ $t('home.intro_v9_icon18') }}</p>
                    </div>
                    <div class="li-box">
                        <i class="icon icon3"></i>
                        <p class="t">{{ $t('home.intro_v9_icon19') }}</p>
                        <p class="p">{{ $t('home.intro_v9_icon20', { 'platform': $tigerConfig.shortName }) }}</p>
                    </div>
                </div> -->
            </div>
            <div class="exchange-intro">
                <div>
                    <p class="title">{{ $t('v42.footer_welcome', { platform: sysconfig.name }) }}</p>
                    <router-link class="link btn btn-primary" to="/futures">{{ $t('v42.footer_start') }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        securityList: {
            type: Array,
            default: () => {
                return []
            }
        },
        otcList: {
            type: Array,
            default: () => {
                return []
            }
        },
        introList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            fileName: 'dist2',
            carouselList: [],
            activeName: 'first',
            introSwitch: 1
        }
    },
    created() { },
    methods: {
        handleClick(tab, event) {
            // console.log(tab)
            // let isActive = (event.srcElement.style.color = '#000')
            // setTimeout(() => {
            //     if (isActive) {
            //         event.srcElement.style.color = '#000'
            //     } else {
            //         event.srcElement.style.color = '#fff'
            //     }
            // }, 0.3)
            // console.log()
        }
    }
}
</script>
<style lang="less" scoped>
.tab-intro {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 300px;

    .left {
        margin-left: 105px;
        margin-right: 155px;
    }

    .right {
        padding-top: 183px;

        .title {
            font-size: 46px;
            line-height: 56px;
            font-weight: 600;
            color: var(--text-title);
            margin-bottom: 100px;
        }
    }
}

.otc-title {
    text-align: center;
    margin-bottom: 120px;
    margin-top: 300px;

    h3 {
        font-size: 46px;
        font-weight: 600;
        line-height: 52px;
        margin-bottom: 15px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: var(--text-desp);
    }
}

.news-title {
    margin-top: 300px;
    margin-bottom: 64px;

    h3 {
        margin-bottom: 30px;
        font-size: 46px;
        font-weight: 600;
    }

    p {
        max-width: 581px;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        color: var(--text-desp);
    }
}

.news-intro {
    margin-bottom: 300px;

    .list-box {
        justify-content: space-between;
        display: flex;

        .list {
            margin-right: 19px;

            &:last-child {
                margin-right: 0;
            }
        }

        .img {
            margin-bottom: 24px;

            img {
                max-width: 100%;
                width: 388px;
                height: 242px;
                display: block;
                margin: 0 auto;
            }
        }

        .desp {
            .t {
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
                margin-bottom: 12px;
            }

            .p {
                line-height: 18px;
                color: var(--text-desp);
            }
        }
    }
}

.otc-intro {
    margin-bottom: 200px;

    .list-box {
        display: flex;
        justify-content: space-between;
    }

    .list {

        // margin-bottom: 72px;
        .img {
            width: 240px;
            height: 100px;
            // margin-right: 26px;
            margin-bottom: 40px;

            img {
                max-width: 100%;
            }
        }

        .desp {
            flex: 1;
            max-width: 240px;

            .t {
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 20px;
                text-align: center;
            }

            .p {
                text-align: center;
                width: 240px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: var(--text-desp);
            }
        }
    }

    .right {
        width: 386px;
        margin-top: 30px;
    }
}

.exchange-intro {
    // background: #fafafa;
    background: url('@/assets/images/v4.2/indexIcon9.png') no-repeat center;
    background-size: contain;
    // width: 1200px;
    max-width: 100%;
    height: 40rem;
    margin: 4rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        text-align: center;
    }

    .title {
        margin-bottom: 4rem;
        font-size: 3rem;
        font-weight: 600;
    }

    .link {
        border-radius: .25rem;
        line-height: 3rem;
        font-size: 1.25rem;
        font-weight: 400;
        padding: 0 3rem;
        display: inline-block;
        color: var(--bg-primary);
        background: var(--text-title);
    }
}

@media (max-width: 768px) {
    .pc-container {
        .otc-title {
            padding: 0 28px;
            box-sizing: border-box;
            margin: 100px 0 51px 0;

            h3 {
                margin: 100px 0 12px 0;
                font-size: 23px;
                font-weight: 600;
                line-height: 28px;
            }

            p {
                font-size: 11px;
                line-height: 17px;
                text-align: center;
                color: var(--text-desp);
            }
        }

        .otc-intro {
            margin-bottom: 0;

            .list {
                display: block;

                .img {
                    margin: 71px auto 22px auto;
                    width: 150px;
                    height: auto;
                }

                .desp {
                    width: auto;
                    max-width: 100%;
                    padding: 0 16px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--text-title);
                    line-height: 20px;

                    .t {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        margin-bottom: 4px;
                    }

                    .p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: center;
                        color: var(--text-desp);
                        width: 208px;
                        margin: 0 auto;
                    }
                }
            }

            .list-box {
                display: block;
                margin-right: 0;
            }
        }

        .platform,
        .platform-title {
            display: none;
            // .right {
            //     display: none;
            // }
        }
    }

    .tab-intro {
        flex-direction: column-reverse;
        margin: 100px 0;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        .left {
            flex: 1;
            margin: 0;

            img {
                display: block;
                width: 210px;
                margin: 0 auto;
            }
        }

        .right {
            margin-bottom: 50px;
            border-radius: 16px;
            padding-top: 0;
            width: 100%;

            .title {
                text-align: center;
                font-size: 23px;
                font-weight: 600;
                line-height: 28px;
                margin-bottom: 0;
                margin-bottom: 32px;
            }

            .tabs-box {
                width: 274px;
                margin: 0 auto;

                /deep/.el-tabs__header {
                    margin-bottom: 28px;

                    .el-tabs__nav-wrap {
                        padding: 0;
                        border-radius: 16px;
                    }

                    .el-tabs__nav {
                        height: 32px;
                        width: 274px;

                        .el-tabs__item {
                            font-size: 12px;
                        }
                    }

                    .el-tabs__active-bar {
                        height: 28px;
                        top: 2px;
                        left: 2px;
                        border-radius: 14px;
                    }
                }

                /deep/.el-tabs__content {
                    .el-tab-pane {
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .news-title {
        margin: 100px 0 50px 0;

        h3 {
            font-size: 23px;
            margin-bottom: 16px;
            text-align: center;
        }

        p {
            text-align: center;
            font-size: 12px;
        }
    }

    .news-intro {
        margin-bottom: 100px;

        .list-box {
            display: block;

            .list {
                margin-bottom: 50px;
                margin-right: 0;

                .img {
                    margin: 0 auto;
                    margin-bottom: 24px;
                    width: 343px;
                }
            }

            .desp {
                text-align: center;

                .t {
                    font-size: 14px;
                    margin-bottom: 16px;
                }

                .p {
                    margin-bottom: 50px;
                    font-size: 12px;
                }
            }
        }
    }

    .exchange-intro {
        height: 295px;
        width: 100%;
        background-size: cover;
        padding-bottom: 0;

        .title {
            font-size: 23px;
            margin-bottom: 45px;
            font-weight: 600;
            width: auto;
        }

        .link {
            font-size: 14px;
            height: 46px;
            line-height: 46px;
            border-radius: 23px;
            padding: 0 32px;
        }
    }
}

@media (max-width: 1200px) {
    .tab-intro {
        flex-direction: column-reverse;
        align-items: center;
        margin: 100px auto;
        width: 100%;
        max-width: 768px;
        padding: 0 30px;
        box-sizing: border-box;

        .left {
            flex: 1;
            margin: 0;
        }

        .right {
            margin-bottom: 50px;
            border-radius: 16px;
            padding-top: 0;
            width: 100%;

            .title {
                text-align: center;
                font-weight: 600;
                margin-bottom: 0;
                margin-bottom: 32px;
            }

            .tabs-box {
                margin: 0 auto;
            }
        }
    }
}

.switch-top {
    display: flex;
    align-items: center;
    height: 64px;
    width: 548px;
    border: 1px solid var(--text-desp);
    border-radius: 64px;
    overflow: hidden;
    margin-bottom: 28px;

    .link {
        color: var(--text-desp);
        flex: 1;
        font-size: 18px;
        font-weight: 400;
        padding: 0;
        position: relative;
        text-align: center;
        z-index: 1;
        border-radius: 28px;
        height: 56px;
        line-height: 56px;
        left: 4px;
        top: 0px;

        &:last-child {
            right: 4px;
            left: auto;
        }

        &.active {
            color: var(--text-title);
        }
    }
}

.switch-content {
    color: var(--text-desp);
    font-size: 20px;
    line-height: 26px;
    text-align: center;

}

@media (max-width: 768px) {
    .switch-top {
        width: 274px;
        height: 32px;
        margin: 0 auto;
        margin-bottom: 28px;

        .link {
            height: 26px;
            line-height: 26px;
            border-radius: 14px;
            font-size: 12px;
            left: 2px;

            &:last-child {
                right: 2px;
                left: auto;
            }
        }

    }

    .switch-content {
        width: 274px;
        color: var(--text-desp);
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 0 auto;
    }


}
</style>
