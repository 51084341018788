<template>
    <form method="post" action="javascript:void(0);" @submit="subscribeToken">
        <div class="row" v-if="token.requiresInviteCode">
            <div class="col">
                <h2>{{ $t('startup.label_invite_code') }}</h2>
                <div class="row">
                    <div class="col-sm-6">
                        <input
                            class="form-control form-control-lg"
                            name="InviteCode"
                            type="text"
                            data-val="true"
                            data-val-required="*"
                            :placeholder="$t('startup.label_invite_code')"
                        />
                    </div>
                </div>
                <div class="help-block">{{ $t('startup.label_invite_code_desp') }}</div>
            </div>
        </div>

        <div class="row required">
            <div class="col">
                <h2>{{ $t('startup.label_support_amount') }}</h2>
                <div class="help-block">
                    {{ $t('startup.label_available_amount') }}
                    <div v-if="isNaN(max_token_amount)" class="spinner-border text-secondary spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <span v-else> {{ max_token_amount }} {{ token.token }}</span>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <input type="hidden" name="Token" :value="token.token" />
                        <div class="input-group">
                            <!-- 
                                data-val-range="*"
                                data-val-range-max="@(maxAmount)"
                                data-val-range-min="@(minAmount)"
                            -->
                            <input
                                class="form-control form-control-lg"
                                name="Amount"
                                v-model="amount"
                                type="number"
                                autocomplete="off"
                                :disabled="isNaN(max_token_amount) || max_token_amount <= 0"
                                data-val="true"
                                data-val-number="*"
                                data-val-range="*"
                                :data-val-range-max="Math.min(max_token_amount, token.maxSubscriptionAmount)"
                                :data-val-range-min="token.minSubscriptionAmount"
                                data-val-required="*"
                                :placeholder="$t('startup.label_support_amount')"
                            />

                            <div class="input-group-text">{{ token.token }}</div>
                        </div>
                    </div>
                </div>
                <div class="help-block">
                    {{ $t('startup.label_min_sub')+':' }}<span>{{ token.minSubscriptionAmount }}</span>
                </div>
                <!-- @if (startup.MinSubscriptionAmount.HasValue || startup.MaxSubscriptionAmount.HasValue) {
                <div class="help-block">
                    @if (startup.MinSubscriptionAmount.HasValue && startup.MaxSubscriptionAmount.HasValue) {
                    <span
                        >@(ExchangeSR.Startup_SubscriptionAmountRange(startup.MinSubscriptionAmount.Value.ToString("#,#0"),
                        startup.MaxSubscriptionAmount.Value.ToString("#,#0")))</span
                    >
                    } else if (startup.MinSubscriptionAmount.HasValue) {
                    <span>@(ExchangeSR.Startup_MinSubscriptionAmount(startup.MinSubscriptionAmount.Value.ToString("#,#0")))</span>
                    } else {
                    <span>@(ExchangeSR.Startup_MaxSubscriptionAmount(startup.MaxSubscriptionAmount.Value.ToString("#,#0")))</span>
                    }
                </div>
                } else if (maxAmount < int.MaxValue) {
                <div class="help-block">@(ExchangeSR.Startup_SubscribeLimit(maxAmount.ToString("#,#0")))</div>
                } -->
            </div>
        </div>
        <!-- @if (startup.CountdownTimer && now < startup.StartTime) {
        <div class="row" id="count-down-row" style="display: none">
            <div class="col">
                <div class="alert alert-danger">@(ExchangeSR.Startup_StartIn): <span id="count-down-timer"></span></div>
            </div>
        </div>
        } -->
        <div class="row">
            <div class="col-md-6">
                <button type="submit" class="btn-submit startup-btn" :class="{ 'btn-loading': submitting }" :disabled="isNaN(max_token_amount) || max_token_amount <= 0">
                    {{ $t('startup.label_subscribe') }}
                </button>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col">
                <h2>{{ $t('startup.label_risk_warning') }}</h2>
                <div v-html="$t('startup.risk_warning')"></div>
            </div>
        </div> -->
    </form>
</template>
<style scoped>
.page button.btn-submit.startup-btn{
    margin-top: 20px;
    border-radius: 8px;
}
</style>
<script>
export default {
    props: ['token'],

    data() {
        return {
            submitting: false,
            amount: '',

            // The amount of available balance
            max_token_amount: NaN
        };
    },

    mounted() {
        $.resetValidators();

        // this.syncBalance();
        const self = this;
        setTimeout(() => {
            self.syncBalance();
        }, 500);
    },

    methods: {
        subscribeToken: function (e) {
            const frm = $(e.target);
            const self = this;

            if (frm.valid()) {
                self.submitting = true;

                $.callPostApi(self, '/api/v1/startuporder/subscribe', frm.serialize())
                    .then((json) => {
                        if (json.errcode === 0) {
                            $.top_alert(self.$t('general.submitted'));

                            // Update the number of tokens that could be purchased
                            setTimeout(() => {
                                self.syncBalance();
                            }, 500);
                        } else {
                            $.top_error(json.errmsg || self.$t('general.operation_error'));
                        }
                    })
                    .catch((err) => {
                        $.top_error(self.$t('general.operation_error'));
                        console.error(`ERROR: ${err}`);
                    })
                    .then(() => {
                        self.submitting = false;
                    });
            } else {
                const val = this.amount * 1;

                // Exceed normal range.
                if (isNaN(val) || isNaN(this.max_token_amount) || val <= this.max_token_amount) $.top_error(self.$t('startup.exceed_max_amount'));
                // Exceed the max available amount
                else $.top_error(self.$t('startup.exceed_available_amount'));
            }
        },

        syncBalance: function () {
            const self = this;
            this.max_token_amount = NaN;

            $.callPostApi(self, '/api/v1/balance/get?currency=USDT').then((json) => {
                if (json && typeof json.balance === 'number') {
                    self.max_token_amount = Math.floor(Math.max(0, json.balance + json.frozen) / self.token.offerPrice);
                } else {
                    self.max_token_amount = 0;
                }

                Vue.nextTick(() => {
                    // Reset validators.
                    $.resetValidators();
                });
            });
        }
    }
};
</script>