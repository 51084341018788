<template>
    <div class="content-top page-content-top futures-top futures-content-top">
        <div class="left">
            <div class="trading-chart">
                <div class="ticker bg-title text-content">
                    <div stop="1" class="symbol-name">
                        <div class="d-flex flex-row">
                            <div class="dropdown" @click.stop="toggleDropdownLeft">
                                <button class="btn btn-secondary custom-arrow" type="button">
                                    {{ $t('general.futures') }}
                                </button>
                                <div v-show="isDropdownVisibleLeft" class="side_right">
                                    <router-link to="/futures" class="dropdown-item">{{ $t('general.futures') }}</router-link>
                                    <router-link to="/exchange" class="dropdown-item">{{ $t('general.exchange') }}</router-link>
                                    <router-link v-if="sysconfig.bOptionEnabled" to="/boption" class="dropdown-item">{{ $t('general.boption') }}</router-link>
                                </div>
                            </div>
                            <div class="dropdown" @click.stop="toggleDropdown">
                                <button class="btn btn-secondary custom-arrow" type="button">
                                    {{ symbol.metadata.name }}
                                </button>
                                <div v-show="isDropdownVisible" class="side_right">
                                    <div class="input-group">
                                        <div class="input-group-text">
                                            <svg viewBox="0 0 1024 1024" width="32" height="32">
                                                <path
                                                    d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z"
                                                    fill="#666666" p-id="2868"></path>
                                            </svg>
                                        </div>
                                        <input type="text" class="form-control" v-model="search_term"
                                            placeholder="" @click.stop/>
                                    </div>
                                    <div class="futures-content">
                                        <router-link v-for="sym in filteredSymbols" :key="sym.id" :to="'/futures/' + sym.symbol.toLowerCase()" class="dropdown-item">
                                            {{ sym.name }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div growing-ignore="true" class="price-container" :class="{ 'color-up': symbol.up, 'color-down': !symbol.up }">
                        <div>{{ symbol.price }}</div>
                        <div class="price-change">{{ symbol.price_change }}</div>
                    </div>
                    <dl growing-ignore="true" class="change">
                        <dt class="text-label price-daychange">{{ $t('futures.24h_change') }}</dt>
                        <dd v-bind:class="symbol.up ? 'color-up' : 'color-down'">{{ symbol.price_change_pct }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="low d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_low') }}</dt>
                        <dd>{{ symbol.day_low }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="high d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_high') }}</dt>
                        <dd>{{ symbol.day_high }}</dd>
                    </dl>
                    <dl growing-ignore="true" class="amount d-none d-sm-block">
                        <dt class="text-label">{{ $t('futures.24h_vol') }}</dt>
                        <dd>{{ symbol.day_vol }}</dd>
                    </dl>
                    <div class="goto-chart" @click="showChart"><i class="fa fa-chart-bar"></i></div>
                </div>
                <div class="trading-chart-container bg-content">
                    <div class="chart-container">
                        <div id="tv_chart_container" class="chart">
                            <!-- Display the loading indicator by default -->
                            <loading-indicator />
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom-trade futures-submit-box">
                <div class="row">
                    <div class="col">
                        <button class="btn-submit bg-buy futures-submit" @click="showTrade(true)">{{ $t('futures.label_buy_up') }}</button>
                    </div>
                    <div class="col">
                        <button class="btn-submit bg-sell futures-submit" @click="showTrade(false)">{{ $t('futures.label_buy_down') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="futures-trade">
            <div class="order-books">
                <OrderBooks :symbol="symbol"  :order_books="order_books" :robotsNumber="robotsNumber" :showTradeEvent="showTradeEvent" @clickEvent="onPriceSelected"/>
            </div>
            <div class="trade bg-content futures-trade-box">
                <div class="mod trade-panel">
                    <div class="mod-head tabs bg-title">
                        <span data-trade-type="exchange" class="mock-a cur">
                            {{ $t('futures.label_trade') }}
                        </span>
                        <div class="actions">
                            <router-link to="/user/balance">{{ $t('general.deposit') }}</router-link>
                        </div>
                    </div>

                    <!-- create orders here -->
                    <create-order-panel ref="createOrderPanel" :symbol="symbol" :futuresConfig="futuresConfig" @create-order="onCreateOrder" />
                </div>
            </div>
        </div>

        <!-- Have to wrap the modal with a section element to avoid layout conflict with other elements: .content-top > div -->
        <section>
            <!-- order confirmation modal -->
            <order-confirmation-modal ref="createOrderModal" :symbol="symbol" />
        </section>
    </div>
</template>
<style scoped>
.order-books .mod-body {
    background: #000;
}
.ticker.bg-title.text-content {
    height: 50px;
    background: #1D2027;
    align-items: center;
    color: #CFD3E9;
}
.bg-title.text-label {
    background: #1D2027;
    color: #CFD3E9;
    font-size: 9px;
}
.now-pric.text-content {
    color: #CFD3E9;
}
.mod-head.tabs.bg-title {
    background: #1D2027;
    color: #CFD3E9;
}
.mod-head.tabs .mock-a.cur {
    border: none;
    color: #F8D134;
}
.content-top{
    position:relative;
}
.content-top .dropdown{
    position: initial;
}
.content-top .dropdown .side_right{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    background: #000;
    color: #fff;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #999;
    top: 40px;
    transform: none;
    left: 0;
    right: 0;
}
.futures-content{
   overflow: auto;
   height: 60vh;
}
.futures-content a,.side_right .form-control{
   color: #000;
}
.bg-content input{
    border: 1px solid #E1E2E6;
}
.trade-layout a {
   font-size: 14px;
}
.futures-content a:hover{
   background: #0A2B9A ;
   color: #fff;
}
.input-group{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 20px;
}
.input-group  .input-group-text,.input-group  .form-control{
    height: 30px;
    background: #1d2027;;
    border: 1px solid #1a1c24;
    color: #fff;
}
.input-group  .input-group-text{
    width: 40px;
    height: 30px;
    background: #1d2027;;
    border: 1px solid #1a1c24;
}
.input-group .form-control:focus{
    border: none;
}
.form-control{
    color: #7e8697;
}
.custom-arrow {
  position: relative;
  padding-right: 25px; 
}


.custom-arrow::after, .custom-arrow::before {
  content: '';
  position: absolute;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.custom-arrow::after {
  top: 8px;
  border-top: 6px solid #494949;
}
.btn.btn-secondary.custom-arrow{
    color: #fff;
}

@media(max-width:1024px){
    .content-top .bottom-trade .btn-submit.futures-submit{
        margin-top: 15px;
    }
    .content-top .bottom-trade.futures-submit-box{
        background: #1d2027;
    }
    .trade.bg-content.futures-trade-box{
        background: #1d2027;
    }
    .order-books .mod-body dl .p-content{
        line-height: 13px;
        height: 16px;
    }
    .order-books .mod-body dl .now-pric.text-content{
        height: 42px;
    }
    .order-books .mod-body dl dd > div.asks.futures-asks{
        bottom: 47px;
    }
    .order-books .mod-body dl dd > div.bids.futures-bids {
        top: 39px;
    }
    .content-top .left .trading-chart .ticker .goto-chart{
        color: #cfd3e9;
    }
    .content-top .left .trading-chart .ticker .price-container>div{
        font-size: 12px;
    }
    .content-top .left .trading-chart .ticker .price-container>div.price-change{
        font-size: 10px;
    }
    .content-top .left .trading-chart .ticker .text-label.price-daychange{
        font-size: 12px;
    }
    .content-top .left .trading-chart .ticker .price-container{
        margin-right: 10px;
    }
}
@media (max-width: 768px) {
    .content-top {
        height: 800px;
    }
    .content-top .left .trading-chart .ticker .goto-chart{
        color: #cfd3e9;
    }
    .content-top .left .trading-chart .ticker .price-container>div{
        font-size: 12px;
    }
    .content-top .left .trading-chart .ticker .price-container>div.price-change{
        font-size: 10px;
    }
    .content-top .left .trading-chart .ticker .text-label.price-daychange{
        font-size: 12px;
    }
    .content-top .left .trading-chart .ticker .price-container{
        margin-right: 10px;
    }
}
</style>
<script>
import { getSymbolManagerAsync } from 'utilities/helper';
import * as chart from 'utilities/QuoteDataFeed.js';
import { Dropdown } from 'bootstrap';

import CreateOrderPanel from './Futures_CreateOrderPanel.vue';
import OrderConfirmationModal from './Futures_OrderConfirmationModal.vue';
import OrderBooks from '@/pages/Components/OrderBooks.vue';
// The date time when the last quote is received.
let g_lastQuoteTime = new Date();

export default {
    components: { CreateOrderPanel, OrderConfirmationModal ,OrderBooks},

    props: ['symbol', 'futuresConfig'],

    data() {
        return {
            showTradeEvent:false,
            order_books: {
                asks: [],
                bids: []
            },

            symbols: [],
            chartVersion: 0,

            // Indicates whether the component has been destoyed or not.
            destroyed: false,

            isDropdownVisible: false,
            isDropdownVisibleLeft: false,
            search_term:null,
            robotsNumber:null
        };
    },
    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }
            const term =
                this.search_term && typeof this.search_term === 'string'
                    ? this.search_term.trim().toUpperCase() 
                    : '';
            if (term && term.length > 0) {
                let data = this.symbols.filter((s) => {
                    return s.name.indexOf(term) >= 0;
                });
                return data
            } else {
                return this.symbols
            }
        },
    },
    created() {
        this.destroyed = false;
        this.getFuturesSymbols();
    },

    beforeDestroy() {
        console.log(`## Destroy the futures page.`);
        this.destroyed = true;

        // Stop socket io connections
        $.closeSocketIo();
        chart.destroyChart();
    },

    mounted() {
        this.initChartAsync();

        const dropdownElementList = document.querySelectorAll('.dropdown-toggle')
        const dropdownList = [...dropdownElementList].map(elm => {
            $(elm).click(() => {
                const d = Dropdown.getOrCreateInstance(elm);
                d.show();
            });
        });
        if(document.body.clientWidth>1024){
            this.robotsNumber= 5;
        }
        //只有在PC端显示5个  其他端都是自适应
        window.onresize=()=>{
            if(document.body.clientWidth>1024){
                this.robotsNumber= 5;
            }else{
               this.robotsNumber= null; 
            }
        }
    },

    methods: {
        closeDropdownFun(){
            this.isDropdownVisible=false;
            this.isDropdownVisibleLeft=false 
        },
        toggleDropdown() {
            this.isDropdownVisibleLeft = false;
            this.isDropdownVisible = !this.isDropdownVisible;
        },
        closeDropdown() {
            this.isDropdownVisible = false;
        },
        toggleDropdownLeft() {
            this.isDropdownVisibleLeft = !this.isDropdownVisibleLeft;
            this.isDropdownVisible = false;
        },
        closeDropdownLeft() {
            this.isDropdownVisibleLeft = false;
        },
        initChartAsync: async function () {
            const sym = this.symbol;
            const self = this;

            // Clear any existing klines
            chart.setInitialKlines(null);

            // Read initial klines
            const end_ts = Math.floor(new Date().setSeconds(0, 0) / 1000);
            const from_ts = end_ts - 1500 * 60; // request klines for past 1500 minutes.
            const request_url = `/api/v1/quotation/klines?id=${encodeURIComponent(sym.metadata.id)}&type=1&from=${from_ts}&to=${end_ts}&limit=1500`;

            try {
                const resp = await $.callPostApi(self, request_url);
                if (resp) {
                    chart.setInitialKlines(resp);
                }
            } catch (err) {
                console.log(`Failed to read initial klines: ${err}`);
            }

            // Initialize the tradingview chart
            chart.initializeChart(sym, {
                region: this.sysconfig.region,
                locale: this.$i18n.locale,
                uiVersion: this.$version,
                defaultInterval: this.futuresConfig.defaultInterval,
                tzOffset: this.sysconfig.tzOffset
            });

            // Start a socket io connection
            g_lastQuoteTime = new Date();
            const chartVersion = ++this.chartVersion;

            $.initSocketIo(
                '/f' + this.symbol.metadata.id,
                (quote) => {
                    if (self.destroyed === false) {
                        if (self.chartVersion !== chartVersion) {
                            return;
                        }

                        // Is there a big gap for the latest quote?
                        let now = new Date();
                        const gap = now - g_lastQuoteTime;
                        if (gap > 300 * 1000) {
                            setTimeout(() => {
                                console.log('#### rebuild chart ######');
                                $.closeSocketIo();
                                self.initChartAsync();
                            }, 0);
                        } else {
                            g_lastQuoteTime = now;

                            // Update quote.
                            sym.updateRtqs(quote);
                            chart.updateRtqsToChart(quote);

                            // Notify that the price is updated.
                            self.$emit('price-changed', quote);
                        }
                    }
                },
                (depth) => {
                    // Parse depth data
                    self.updateOrderBooks(depth);
                }
            );
        },

        parseDepth: function (src, key_prefix) {
            const pricePrecison = this.symbol.metadata.pricePrecision;
            const amountPrecison = this.symbol.metadata.volumePrecision;
            const self = this;
            let total_vol = 0;
            const arr = [];
            let median = self.medianCalc(src)
            for (let i = 0; i < src.length; i++) {
                let item = src[i];
                total_vol += item[1];
                arr[i] = {
                    key: key_prefix + i,
                    price: item[0].toFixed(pricePrecison),
                    volume: item[1].toFixed(amountPrecison),
                    total: total_vol.toFixed(amountPrecison),
                    width: 100 * Math.min(item[1].toFixed(amountPrecison) / median, 1).toFixed(2) + '%'
                };
            }

            return arr;
        },

        updateOrderBooks: function (depth) {
            this.order_books.bids = this.parseDepth(depth.buy, 'bid');
            this.order_books.asks = this.parseDepth(depth.sell, 'ask');
        },

        medianCalc(array) {
            // 深拷贝  sort会修改原数组，如果数组过大会影响性能
            let arr = JSON.parse(JSON.stringify(array))
            arr.sort((a, b) => {
                if (a[1] < b[1]) return -1
                if (a[1] > b[1]) return 1
                return 0
            })
            //求中位数
            if (arr.length % 2 == 0) {
                return (arr[arr.length / 2 - 1][1] + arr[arr.length / 2][1]) / 2
            } else {
                return arr[Math.floor(arr.length / 2)][1]
            }
        },


        getFuturesSymbols: function () {
            const self = this;
            getSymbolManagerAsync().then((mgr) => {
                self.symbols = mgr.getSymbols(2); // 2: futures symbol
            });
        },

        setAvailableMargin: function (margin) {
            this.$refs.createOrderPanel.setAvailableMargin(margin);
        },

        showTrade: function (up) {
            this.$refs.createOrderPanel.setOrderType(up);
            $('.trade-layout').addClass('skip-chart');
            this.showTradeEvent =true;
        },

        showChart: function () {
            $('.trade-layout').removeClass('skip-chart');
        },

        onPriceSelected: function (price) {
            // Do nothing as we don't support pending orders now.
            // this.$refs.createOrderPanel.setOrderPrice(price);
        },

        onOrderCreated: function () {
            this.$emit('order-created');
        },

        onCreateOrder: function (options) {
            this.$refs.createOrderModal.openModal(options);
        }
    }
};
</script>