<template>
    <div ref="popupModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <div v-html="notice.title"></div>
                    </h4>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>

                <div class="modal-body">
                    <!-- Need to query full notice body from server -->
                    <loading-indicator v-if="!body" />
                    <pre v-else v-html="body" class="agreement"></pre>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    props: ['notice'],

    data() {
        return {
            // The full body of the selected notice.
            body: null,
            popupModal: null,
        };
    },

    mounted() {
        // Display the popup
        this.popupModal = new Modal(this.$refs.popupModal);
        this.popupModal.show();

        // Query notice body from server
        this.queryNoticeAsync();
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        queryNoticeAsync: async function () {
            const nid = this.notice.id;
            const resp = await axios.get(g_server_root + '/api/v1/notice?id=' + encodeURIComponent(nid));
            const json = resp.data;
            if (json && json.errcode === 0) {
                this.body = json.data.body;
            }
        }
    }
};
</script>