<template>
    <footer class="footer-simple">
        <ul>
            <li>
                <router-link to="/">{{ $t('home.title') }}</router-link>
            </li>
            <template v-if="footer && footer.length > 0">
                <li v-for="item in footer[0].items" :key="item.id">
                    <router-link rel="noopener noreferrer nofollow" :to="'/post/' + item.id">{{ item.title }}</router-link>
                </li>
            </template>
            <li>&copy; 2023 {{ sysconfig.name }}</li>
        </ul>
    </footer>
</template>


<script>
import { getAppContext } from 'utilities/helper';
export default {
    data() {
        return { context: null };
    },

    computed: {
        footer: function () {
            const footerData =  this.context.homeConfig ? this.context.homeConfig.footer : null;
            //Hide news content
            if(footerData) {
                // 隐藏新闻资讯内容，固定为数组中的第二个
                footerData.splice(1, 1);
                return footerData
            } else {
                return null
            }
        }
    },

    created() {
        this.context = getAppContext();
    }
};
</script>