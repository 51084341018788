<template>
    <form class="login-form" method="post" action="javascript:void(0)" v-on:submit="do_login">
        <div class="pc-title-box">
            <div class="pc-title">{{ $t('login.title') }}</div>
        </div>
        <input type="hidden" name="__RequestVerificationToken" v-bind:value="csrfToken" />
        <div class="form-group">
            <label>{{ $t('login.account') }}</label>
            <div class="d-flex">
                <region-selector v-bind:regions="regions" />
                <div class="flex-fill">
                    <input name="UserName" data-val="true" data-val-required="*" pattern="\d*" type="text" value="" class="form-control round-right phone" :placeholder="$t('login.account')" />
                </div>
            </div>
        </div>
        <div class="form-group" :style="{ opacity: 0, width: 0,height: 0 ,margin:0 ,padding:0} " >
            <!--  This hidden input is used to solve the input flickering problem of Apple mobile phones -->
            <input type="text" style="opacity: 0;width: 0;height: 0" />
        </div>
        <div class="form-group">
            <label for="Password">{{ $t('login.password') }}</label>
            <div class="password-box">
                <input id="Password" v-model="password" name="Password" :type="showPassword?'text':'password'" autocomplete="off" data-val="true" data-val-required="*" class="form-control" :placeholder="$t('login.password')" />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <!-- Google Authenticator Code -->
        <div class="form-group">
            <label class="form-label" for="GAPin">{{ $t('ga.label_code') }}</label>
            <input name="GAPin" v-model.trim="GAPin" type="text" autocomplete="off" maxlength="6" pattern="\d*" data-val-regex="*" data-val-regex-pattern="^\d{6}$" class="form-control" :placeholder="$t('ga.label_code')" />
            <div class="help-block">{{ $t('login.ga_pin_desp') }}</div>
        </div>
        <div class="form-group">
            <submit-button ref="submitButton" :text="$t('login.login_button')" />
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col padding-top">
                    <router-link to="/user/reset">{{ $t('login.forgot_password') }}</router-link>
                </div>
                <!-- Do we need to display the registration link? If could be configured on the server. -->
                <div class="col text-right padding-top">
                    <router-link to="/user/create">{{ $t('login.free_register') }}</router-link>
                </div>
            </div>
        </div>
    </form>
</template>
<style scoped>
.padding-top{
    padding-top: 10px;
}
.pc-title-box {
    display: block;
}
.pc-title-box .pc-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 20px 0 20px 0;
}
.password-box {
    position: relative;
}
.password-box .password-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
}
.password-icon.active {
    fill: var(--primary-base);
    stroke: var(--primary-base);
}
.login-page .form-control {
    background-color: #fff;
    border-radius: 0;
}
.login-page .form-control:focus {
    border: 1px solid #1673F9;
}
.form-group label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #40475A;
    line-height: 1.25rem;
    margin-bottom: .5rem;
}
.d-flex .flex-fill .form-control {
    background-color: #fff;
    border-radius: 0;
}

.form-group:nth-child(6) {
    margin-bottom: 0;
}
.login-page .form-control {
    font-size: 1rem;
}
.help-block {
    margin-bottom: 15px;
}
.login-form {
    width: 100%;
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },

    props: ['regions', 'csrfToken'],

    created() {
        const regions = this.regions;
        if (!regions || !Array.isArray(regions)) throw new Error('An array of supported regions is required.');

        const token = this.csrfToken;
        if (!token || typeof token !== 'string') throw new Error('A valid CSRF token is required.');

        if (this.supportMultipleRegions) {
            this.regionCode = regions[0].areaCode;
        }
    },

    data() {
        return { regionCode: null,showPassword:false,password:'',GAPin:null };
    },

    computed: {
        supportMultipleRegions: function () {
            const regions = this.regions;
            return regions != null && regions.length > 0;
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        do_login: function (e) {
            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                self.$refs.submitButton.submit();

                const _on_submit_error = function (err) {
                    const _callback = function () {
                        $.top_error(err || self.$t('login.login_failed'));
                        self.$refs.submitButton.reset();
                    };
                    setTimeout(_callback, 5000);
                };

                self.$http
                    .post('/api/v1/account/signin', frm.serialize(), { headers: { 'X-XSRF-TOKEN': self.csrfToken } })
                    .then((resp) => {
                        const json = resp.data;

                        if (json && json.errcode == 0) {
                            // Save the user profile.
                            setProfile(json.data);

                            self.$refs.submitButton.complete();
                            setTimeout(function () {
                                // Jump to the real-name authentication page without real-name
                                if (json.data.verifiedLevel < json.data.requiredIDLevel){
                                    self.$router.push('/user/verifyid');
                                } else {
                                    self.getBindBankStatus()
                                }
                            }, 200);
                        } else if (json && json.errcode == 104){
                            _on_submit_error(self.$t('login.login_ga_err'));
                        }
                        else {
                            _on_submit_error(json.errmsg);
                        }
                    })
                    .catch((err) => {
                        console.log('Login failed' + JSON.stringify(err));
                        _on_submit_error();
                    });
            }
        },

        async getBindBankStatus(){
            const from = $.trim(this.$route.query.from);
            //if return page is signout ,jump to homepage
            if( from === '/user/signout' ) {
                this.$router.push('/');
            } else if(from.length && from.startsWith('/')) {
                this.$router.push(from);
            } else {
                this.$router.push('/');
            }
        }
    }
};
</script>