<template>
    <section class="page futures-page">
        <loading-indicator v-if="!symbol" />
        <template v-else>
            <div @click="closeDropdownFun" class="w-100 futures-main">
                <futures-top ref="pageTop" :symbol="symbol" :futuresConfig="futuresConfig" @order-created="onOrderCreated" @price-changed="onPriceChanged" />
                <futures-bottom ref="pageBottom" @available-margin-updated="onMarginUpdated" />
            </div>
        </template>
    </section>
</template>
<style scoped>
.page.futures-page {
    background: #15181C;
    --bg-primary: none;
    --bg-secondary: none;
}
</style>
<script>
import FuturesTop from './Components/Futures_Top.vue';
import FuturesBottom from './Components/Futures_Bottom.vue';

import { getSymbolManagerAsync } from 'utilities/helper';
import { SymbolInfo } from 'utilities/SymbolInfo';
import axios from 'axios';

export default {
    components: { FuturesTop, FuturesBottom },

    /**
     * id: the unique id of the futures pair, e.g. btc_usdt.
     */
    props: ['id'],

    data() {
        return {
            // The current symbol.
            symbol: null,

            // The configuration for the futures.
            futuresConfig: null
        };
    },

    watch: {
        $route() {
            this.delayInit(this.id);
        },
        '$i18n.locale': function (newVal, oldVal) {
            this.delayInit(this.id);
        }
    },

    created() {
        this.delayInit(this.id);
    },

    methods: {
        closeDropdownFun() {
            this.$refs.pageTop.closeDropdownFun();
        },
        delayInit: function (id) {
            const self = this;

            this.symbol = null;
            setTimeout(() => {
                self.getSymbolAsync(id);
            }, 200);
        },

        getSymbolAsync: async function (id) {
            this.symbol = null;
            this.boptionConfig = null;

            const mgr = await getSymbolManagerAsync();
            let symbol = null;
            if (!id) {
                // Use the first exchange symbol
                let symbols = mgr.getSymbols(2); // 2: futures symbol
                if (symbols.length > 0) {
                    symbol = symbols[0];
                }
            } else {
                symbol = mgr.getSymbol(id, 2); // 2: futures symbol
            }

            if (symbol && symbol.type === 2) {
                // read symbol config from server.
                const json = await $.callPostApi(this, '/api/v1/futures/config');
                if (json && json.errcode === 0 && json.data && id === this.id) {
                    const config = Object.freeze(json.data);

                    this.symbol = new SymbolInfo(symbol);
                    this.futuresConfig = config;

                    this.getLatestQuote();
                }
            }
        },

        onMarginUpdated: function (margin) {
            this.$refs.pageTop.setAvailableMargin(margin);
        },

        onOrderCreated: function () {
            this.$refs.pageBottom.syncOrders();
        },

        onPriceChanged: function (quote) {
            this.$refs.pageBottom.updatePrice(quote.id, quote.v[1]);
        },

        getLatestQuote: function () {
            const self = this;
            const sym = this.symbol;
            const sid = sym.metadata.id;
            axios.get('/api/v1/quotation/full_quote?id=' + encodeURIComponent(sid)).then((resp) => {
                const json = resp.data;
                if (json && json.quote) {
                    // Update price for the current symbol                    
                    sym.update(json.quote);

                    // Update price for possible open/pending orders?
                    self.$refs.pageBottom.updatePrice(json.quote.id, json.quote.c);
                    if (json.depth) {
                        self.$refs.pageTop.updateOrderBooks(json.depth);
                    }
                }
            });
        }
    }
};
</script>
<style scoped>
.futures-page .futures-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 5rem;
}
</style>