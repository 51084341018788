<template>
    <div class="row">
        <div class="col balance-list">
            <div class="total">
                <div class="actions"></div>
            </div>

            <dl class="title-row">
                <dt>
                    <div class="hidden-sm">{{ $t('assets.account_type') }}</div>
                    <div>{{ $t('assets.available_balance') }}</div>
                    <div>{{ $t('assets.frozen') }}</div>
                    <div>{{ $t('assets.balance') }}</div>
                    <div class="hidden-sm">{{ $t('home.symbol_action') }}</div>
                </dt>
            </dl>

            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="balance in balance_list" class="currency-row" :key="balance.currency">
                <dd>
                    <div>{{ getTokenName(balance) }}</div>
                    <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div v-else>{{ balance.available }}</div>
                    <div>{{ balance.frozen }}</div>
                    <div>{{ balance.balance }}</div>
                    <div class="actions">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <router-link :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{ $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>

                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency) && deposit_currency === balance.currency" class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" />
                </div>
            </dl>

        </div>
    </div>
</template>
<style scoped>
.row {
    border-top: 1px solid #EDEEEF;
}
.balance-list {
    padding: 10px 40px;
}
.title-row div,
.currency-row div {
    text-align: left;
}
.title-row div:last-child,
.currency-row div:last-child {
    text-align: right;
}
.currency-row {
    --bg-secondary: #F8F8F8;
    font-size: 18px;
}
.actions a {
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
}
.actions a:first-child {
    border-right: 1px solid #EDEEEF;
}
.actions a:last-child {
    padding-right: 0;
}
.actions a.disabled {
    color: #969699 !important;
}
</style>
<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';

export default {
    components: { DepositForm },
    props: ['balance_list', 'futures_assets', 'bcConfig'],

    data() {
        return {
            coinMap: {},

            // The active currency for deposits
            deposit_currency: ''
        }
    },

    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
    },

    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        }
    }
}
</script>