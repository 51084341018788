<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="home_quotes">
                        <loading-indicator v-if="!symbols" />
                        <template v-else>
                            <div class="list-tab">
                                {{ $t('v42.label_futures') }}
                            </div>
                            <symbol-list ref="sym_list" :symbols="symbols" :typeFilter="3" @symbol-selected="gotoTradePage" :indexPage="true"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-notices-box">
            <div class="nav-notices nav-notices-md container">
                <div class="nav-notice-item">
                    <div class="img"><img src="@/assets/images/oriented.png" /></div>
                    <div class="item-des">{{ $t('v42.security_t1') }}</div>
                </div>
                <div class="nav-notice-item">
                    <div class="img"><img src="@/assets/images/trading.png" /></div>
                    <div class="item-des">{{ $t('home.24h_vol') }}</div>
                </div>
                <div class="nav-notice-item">
                    <div class="img"><img src="@/assets/images/professional.png" /></div>
                    <div class="item-des">{{ $t('v42.news_title2') }}</div>
                </div>
            </div>
        </div>
        <div class="link-box link-box-md container">
            <img class="link-img-md" src="../../../assets/images/v4.2/indexPageImg.png" alt="">
            <div class="link-box-right">
                <div class="link-box-title">{{ $t('intro.title') }}</div>
                <div class="link-box-des">
                    <p>{{ $t('intro.desp') }}</p>
                </div>
                <router-link to="/exchange" class="link-to-btn link-to-btn-md">{{ $t('v42.footer_start') }}</router-link>
            </div>
        </div>
        <div class="reviews reviews-md container">
            <h2>{{ $t('intro_43.icon15') }}</h2>
            <div class="reviews-nav">
                <div class="nav-item">
                    <img class="item-img" src="@/assets/images/v4.2/reviewsNav1.png" alt="">
                    <div class="nav-item-des nav-title">{{ $t('v42.news_title') }}</div>
                    <div class="nav-item-des">{{ $t('v42.news_desp') }}</div>
                </div>
                <div class="nav-item">
                    <img class="item-img" src="@/assets/images/v4.2/reviewsNav2.png" alt="">
                    <div class="nav-item-des nav-title">{{ $t('v42.news_title1') }}</div>
                    <div class="nav-item-des">{{ $t('v42.news_desp1') }}</div>
                </div>
                <div class="nav-item">
                    <img class="item-img" src="@/assets/images/v4.2/reviewsNav3.png" alt="">
                    <div class="nav-item-des nav-title">{{ $t('v42.news_title2') }}</div>
                    <div class="nav-item-des">{{ $t('v42.news_desp2',{platform:sysconfig.name}) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.list-tab {
    margin-top: 62px;
    margin-bottom: 40px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    color: #18191C;
    line-height: 32px;
}
.nav-notices-box {
    background-color: #1673F9;
    padding: 49px 0;
    width: 100%;
    margin-top: 60px;
}
.nav-notices {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}
.item-num {
    font-family: DINAlternate, DINAlternate;
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
    line-height: 56px;
    text-align: center;
}
.item-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
    margin-top: 7px;
    text-align: center;
}
.link-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 80px auto 0;
}
.link-box-right {
    width: 390px;
}
.link-box-title {
    word-wrap: break-word;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 30px;
    color: #18191C;
    line-height: 42px;
    margin-bottom: 24px;
    text-align: center;
}
.link-box-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #79818F;
    line-height: 22px;
    margin-bottom: 44px;
    text-align: center;
}
.link-box-des p {
    margin: 0;
}
.link-to-btn {
    display: block;
    width: 100%;
    height: 44px;
    border-radius: 4px;
    background: #1673F9;
    text-align: center;
    line-height: 44px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
.reviews {
    margin: 100px auto 70px;
}
.reviews h2 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 32px;
    color: #18191C;
    line-height: 66px;
    margin-bottom: 40px;
}
.reviews-nav {
    display: flex;
    justify-content: space-between;
}
.nav-item {
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,0.1);
    overflow: hidden;
    width: 380px;
    position: relative;
}
.nav-item .nav-item-des {
    padding: 20px;
    word-wrap: break-word;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #18191C;
    line-height: 22px;
}
.nav-logo-box {
    padding: 20px;
}
.nav-item .nav-title{
    font-weight: 600;
    color: #000;
}
.nav-notices-md .nav-notice-item .img {
    text-align: center;
}
@media (max-width: 1200px) {
    .nav-item .nav-item-des {
        padding: 10px 20px;
    }
}
@media (max-width: 768px) {
    .nav-notices {
        display: flex;
        flex-direction: column;
    }
    .list-tab {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #18191C;
        line-height: 28px;
        padding-bottom: 4px;
        margin: 44px 0 24px;
    }
    .nav-notices-md {
        justify-content: center;
        flex-wrap: wrap;
        padding: 40px;
    }
    .nav-notices-md .nav-notice-item {
        margin-bottom: 18px;
    }
    .nav-notices-md .item-num {
        font-size: 32px;
        line-height: 37px;
    }
    .nav-notices-md .item-des {
        margin-top: 0;
        font-size: 14px;
    }
    .link-box-md {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 0 25px;
        flex-wrap: wrap;
        margin-top: 39px;
    }
    .link-box-md .link-box-right {
        width: 100%;
    }
    .link-box-md .link-to-btn-md {
        display: none;
    }
    .link-box-md .link-img-md {
        transform: scale(80%);
    }
    .reviews-md {
        margin-top: 24px;
        padding: 0 16px;
    }
    .reviews-md h2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 28px;
        color: #18191C;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .reviews-md .reviews-nav {
        overflow-x: scroll;
    }
    .reviews-md .reviews-nav::-webkit-scrollbar {
        display: none;
    }
    .reviews-md .nav-item {
        flex-shrink: 0;
        width: 280px;
        margin-right: 16px;
    }
    .reviews-md .nav-item .item-img {
        width: 280px;
    }
    .reviews-md .nav-item-des {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #18191C;
    }
    .nav-item .nav-title{
        font-weight: 600;
        color: #000;
    }
    .nav-notices-box {
        padding: 0;
    }
}
</style>

<script>
import SymbolList from './SymbolList.vue';

export default {
    components: { SymbolList },

    props: ['symbols'],

    methods: {
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        getVisibleSids: function () {
            return this.symbols ? this.$refs.sym_list.getVisibleSids() : [];
        }
    }
};
</script>