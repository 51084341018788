
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/css/v4.2/main.css';
import '@/assets/css/v4.2/vip.less';

import Loader from '@/pages/Components/LoadingIndicator-4.1.vue';
import LoaderSM from '@/pages/Components/LoadingIndicator-sm-4.1.vue';

import Vue from 'vue';
Vue.component('loading-indicator', Loader);
Vue.component('loading-indicator-sm', LoaderSM);

/*
 * ======================================================================================
 * Application entry.
 */
import entry from '../Entry-4.2.vue';

import { initApp } from './Common.js';
import { getRoutes } from './Routes/Routes-4.2.js';

const routes = getRoutes();
initApp(entry, routes, /* app version */'4.2');