<template>
    <section>
        <table class="table compact startup-table">
            <thead>
                <tr>
                    <th class="padding-th text-center">{{ $t('startup.label_token') }}</th>
                    <th class="d-none d-md-table-cell padding-th text-center">{{ $t('startup.orders_start_time') }}</th>
                    <th class="d-none d-md-table-cell padding-th text-center">{{ $t('startup.orders_end_time') }}</th>
                    <!-- <th class="d-none d-md-table-cell">{{ $t('startup.label_grant_date') }}</th> -->
                    <th class="padding-th text-center" v-if="ongoing_tokens">{{ $t('startup.label_progress') }}</th>
                    <th class="padding-th text-center">{{ $t('startup.label_target_amount') }}</th>
                    <th class="padding-th text-center">{{ $t('startup.label_offer_price') }}</th>
                    <th :class="ongoing_tokens ? 'd-none d-md-table-cell text-center' : ''" class="padding-th">{{ $t('startup.label_max_sub') }}</th>
                    <th :class="ongoing_tokens ? 'd-none d-md-table-cell text-center' : ''" class="padding-th">{{ $t('startup.label_min_sub') }}</th>
                    <th class="padding-th" v-if="ongoing_tokens"></th>
                </tr>
            </thead>
            <tbody v-if="tokens">
                <tr v-for="token in tokens" :key="token.id">
                    <td>
                        <div class="d-flex align-items-center justify-content-center">
                            <div v-if="token.svgIcon" class="pe-2">
                                <startup-icon :token="token" />
                            </div>
                            <div>{{ token.token }}</div>
                        </div>
                    </td>
                    <td class="d-none d-md-table-cell text-center">{{ new Date(token.startTime).formatDateTime() }}</td>
                    <td class="d-none d-md-table-cell text-center">{{ new Date(token.endTime).formatDateTime() }}</td>
                    <td v-if="ongoing_tokens" class="text-center">
                        <token-progress :percentage="token.subscribedAmount / token.targetAmount" />
                    </td>
                    <td class="text-center">{{ token.targetAmount }}</td>
                    <td class="text-center">{{ token.offerPrice.toFixed(token.pricePrecision) }}</td>
                    <td :class="ongoing_tokens ? 'd-none d-md-table-cell text-center' : ''">{{ token.maxSubscriptionAmount }}</td>
                    <td :class="ongoing_tokens ? 'd-none d-md-table-cell text-center' : ''">{{ token.minSubscriptionAmount }}</td>
                    <td v-if="ongoing_tokens">
                        <router-link :to="'/startup/' + token.token.toLowerCase()" class="btn btn-primary">{{ $t('startup.label_subscribe_sm') }}</router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <loading-indicator v-if="!tokens" />
        <template v-else>
            <div v-if="tokens.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </template>
    </section>
</template>

<style scoped>
.startup-table td {
    vertical-align: middle;
    padding: 0.5rem 1.5rem;
}
.startup-table {
    border-color: #cccccc;
}
.startup-table .padding-th {
    padding: .5rem 1.5rem;
}
.region-ex .table.startup-table{
    --bs-table-bg: #fff;
}
@media(max-width:1024px){
    .startup-table .padding-th {
        padding: 0.25rem;
        font-size: 12px;
    }
    .startup-table td {
        padding: 0.25rem;
    }
}
</style>

<script>
import StartupIcon from '../../Components/_StartupIcon.vue';
import TokenProgress from './_Progress.vue';

export default {
    components: { StartupIcon, TokenProgress },
    props: ['ongoing_tokens'],

    data() {
        return {
            tokens: null
        };
    },

    watch: {
        /**
         * Update token list when the ongoing_tokens property is updated.
         */
        ongoing_tokens: function () {
            this.tokens = null;
            this.getTokensAsync();
        }
    },

    mounted() {
        this.getTokensAsync();
    },

    methods: {
        getTokensAsync: async function () {
            const self = this;
            const json = await $.callGetApi(self, '/api/v1/startup/' + (this.ongoing_tokens ? 'ongoing' : 'forecast'));
            if (json && json.errcode === 0) {
                self.tokens = json.data;

                Vue.nextTick(() => {
                    $.scanPopup();
                });
            }
        }
    }
};
</script>