<template>
    <section class="page trade-page trade-bg">
        <loading-indicator v-if="!symbol" />
        <template v-else>
            <div @click="closeDropdownFun" class="w-100 exchange-main">
                <exchange-top  ref="exchangeTop" :symbol="symbol" @order-created="onOrderCreated" />
                <exchange-bottom ref="exchangeBottom"  @order-cancel="orderCancel"  :symbol="symbol" />
            </div>
        </template>
    </section>
</template>

<script>
import ExchangeBottom from './Components/Exchange_Bottom.vue';
import ExchangeTop from './Components/Exchange_Top.vue';
import { getSymbolManagerAsync } from 'utilities/helper';
import { SymbolInfo } from 'utilities/SymbolInfo';

export default {
    components: { ExchangeBottom, ExchangeTop },

    /**
     * id: the unique id of the exchange pair, e.g. btc_usdt.
     */
    props: ['id'],

    data() {
        return {
            // The current symbol.
            symbol: null
        };
    },

    watch: {
        $route() {
            this.delayInit(this.id);
        },
        '$i18n.locale': function (newVal, oldVal) {
            this.delayInit(this.id);
        }
    },

    created() {
        this.delayInit(this.id);
    },

    methods: {
        orderCancel(){
            this.$refs.exchangeTop.orderCancel();
        },
        closeDropdownFun() {
            this.$refs.exchangeTop.closeDropdownFun();
        },
        delayInit: function (id) {
            const self = this;

            this.symbol = null;
            setTimeout(() => {
                self.getSymbolAsync(id);
            }, 200);
        },

        getSymbolAsync: async function (id) {
            this.symbol = null;

            const mgr = await getSymbolManagerAsync();
            let symbol = null;
            if (!id) {
                // Use the first exchange symbol
                let symbols = mgr.getSymbols(3); // 3: exchange symbol
                if (symbols.length > 0) {
                    symbol = symbols[0];
                }
            } else {
                symbol = mgr.getSymbol(id, 3); // 3: exchange symbol
            }

            if (symbol) {
                this.symbol = new SymbolInfo(symbol);
            }
        },

        onOrderCreated: function () {
            this.$refs.exchangeBottom.syncOrders();
        }
    }
};
</script>
<style scoped>
.page.trade-page.trade-bg{
    background: #15181c;
}
.trade-page .exchange-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 5rem;
}
</style>