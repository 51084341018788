<template>
    <nav aria-label="navigation" class="mt-3 d-flex justify-content-center" v-if="pages.length > 1">
        <ul class="pagination">
            <li class="page-item previous-btn" :class="{ disabled: current_page === 0 }">
                <a class="page-link" href="javascript:;" aria-label="Previous" @click="onPage($event, current_page - 1)">&laquo;</a>
            </li>
            <li
                class="page-item"
                v-for="(page, index) in visiblePages"
                :key="index"
                :class="{ disabled: current_page === page }"
            >
                <a class="page-link" href="javascript:;" @click="onPage($event, page)">{{ page + 1 }}</a>
            </li>
            <li class="page-item next-btn" :class="{ disabled: current_page >= pages.length - 1 }">
                <a class="page-link" href="javascript:;" aria-label="Next" @click="onPage($event, current_page + 1)">&raquo;</a>
            </li>
        </ul>
    </nav>
</template>

<style scoped lang="less">
.page-link {
    background: #fff;
    border-color: #1673F9;
    color: #1673F9;
    &:hover {
        color: #fff;
        background-color: #1673F9;
    }
}
.page-item.disabled .page-link {
    color: #fff;
    background-color: #749cd4;
    border-color: #749cd4;

}
.previous-btn.disabled .page-link,
.next-btn.disabled .page-link {
    filter: grayscale(90%);
    opacity: 0.3;
    cursor: not-allowed;
}
</style>

<script>
export default {
    props: ['total', 'page_size', 'page_index'],

    data() {
        return {
            pages: [],
            current_page: 0,
            visibleCount: 5,  // 只显示5个分页
        };
    },

    computed: {
        visiblePages() {
            const start = Math.max(this.current_page - Math.floor(this.visibleCount / 2), 0);
            const end = Math.min(start + this.visibleCount, this.pages.length);
            return this.pages.slice(start, end);
        }
    },

    mounted() {
        this.resetPages();
    },

    watch: {
        total: function () {
            this.resetPages();
        },
        page_size: function () {
            this.resetPages();
        },
        page_index: function () {
            this.current_page = this.page_index * 1;
        }
    },

    methods: {
        resetPages: function () {
            let pageSize = this.page_size * 1;
            if (isNaN(pageSize)) {
                pageSize = 2;
            }

            let total = this.total * 1;
            if (isNaN(total)) {
                total = 0;
            }

            let maxPage = Math.ceil(total / pageSize);
            this.pages = Array.from({ length: maxPage }, (_, i) => i);
            this.current_page = Math.min(this.current_page, maxPage - 1); // 确保当前页不超出范围
        },

        onPage: function (event, page) {
            event.target.blur();
            if (page < 0 || page >= this.pages.length) return; // 防止越界

            this.current_page = page;  // 更新当前页
            this.$emit('page-changed', page);
        }
    }
};
</script>