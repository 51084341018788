<template>
    <section class="page settings-page">
        <template v-if="profile">
            <section class="top-header">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col ">
                            <div class="d-flex align-items-center fs-1">
                                <img :src="profile.avatarUrl || require('@/assets/images/userAvatar.png')" alt="User Avatar" class="avatar" @click="triggerFileInput">
                                <input type="file" ref="avatarInput" @change="uploadAvatar" accept="image/*" class="hidden-input"/>
                                <div>Hi, {{ profile.realName }}</div>

                                <!-- vip status -->
                                <div v-if="profile.vipLevel > 0" class="d-flex align-items-center vip-label">
                                    <svg class="svg-icon" viewBox="0 0 1024 1024" version="1.1" width="64" height="64">
                                        <path d="M760.6 863.5H304.7c-37.2 0-59.4-20.8-67.4-64.7l-57.8-489.7 186.2 120.2 171.8-231.1 173.4 231.1 175-120.2L828 798.8c-7.2 40.1-30.2 64.7-67.4 64.7z" fill="#FFE4B3"></path>
                                        <path d="M726.1 857.8H270.2c-31.9 0-72.5-13.7-84.4-78.9-0.1-0.4-0.1-0.7-0.2-1.1l-57.8-489.7c-0.8-6.6 2.3-13.1 8-16.7 5.7-3.5 12.9-3.5 18.5 0.1L327 382.9l162.1-218.2c3.3-4.4 8.4-7 13.8-7s10.6 2.6 13.8 6.9l163.4 217.9 161.3-110.8c5.6-3.8 12.9-4.1 18.7-0.5 5.8 3.5 9 10.1 8.2 16.8l-57.8 489.7c0 0.3-0.1 0.7-0.2 1-4.3 23.9-13.5 42.9-27.3 56.6-14.6 14.7-34.3 22.5-56.9 22.5z m-506.3-84.6c8.2 44.1 30 50 50.3 50H726c27.3 0 44.2-16.8 50.3-50l53.3-451.4L686 420.4c-7.6 5.2-18 3.5-23.6-3.9L503 204 345 416.5c-5.4 7.3-15.6 9.1-23.2 4.2L166.4 320.4l53.4 452.8z"></path>
                                        <path d="M495.8 706h-0.2c-6.4-0.1-12.2-3.7-15.2-9.4l-83.6-162.1c-4.4-8.5-1-18.9 7.4-23.3 8.5-4.4 18.9-1 23.3 7.4l68.7 133.1 72.7-133.5c4.6-8.4 15.1-11.5 23.4-6.9 8.4 4.6 11.5 15.1 6.9 23.4L511 697c-3.1 5.6-8.9 9-15.2 9z"></path>
                                    </svg>
                                    <div>{{ $t(profile.vipLevel > 10 ? 'user.label_premium_user' : 'user.vip' + profile.vipLevel) }}</div>
                                </div>
                                <button v-if="sysconfig.checkinEnabled" type="button" class="btn btn-primary btn-sm btn-checkin" :disabled="hasChecked" @click="postCheckIn()">
                                    {{ hasChecked?$t("coin_treasure.signed_in"):$t("coin_treasure.sign_in") }}
                                </button>

                            </div>

                            <!-- identity verification status -->
                            <div v-if="profile.requiredIDLevel > 0" class="mt-4">
                                <div class="fs-5">
                                    <p class="verifyid-link" v-if="profile.verifiedLevel >= profile.requiredIDLevel">
                                        <router-link to="/user/verifyid" class="info-des">
                                            {{ $t('user.label_id_verified') }}
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-checked" />
                                            </svg>
                                        </router-link>
                                    </p>
                                    <p class="verifyid-link" v-else-if="id_status === 1">
                                        <router-link to="/user/verifyid" class="info-des">
                                            {{ $t('user.label_id_waiting') }}
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                        </router-link>
                                    </p>
                                    <p class="verifyid-link" v-else>
                                        <router-link to="/user/verifyid" class="info-des">
                                            {{ $t('user.label_id_incomplete') }}
                                            <svg viewBox="0 0 256 256" class="icon-warning">
                                                <use xlink:href="/dist/svg/icons.svg#icon-warning-sign" />
                                            </svg>
                                        </router-link>
                                    </p>
                                    <p class="help-block">{{ $t('user.label_id_verification_desp') }}</p>
                                    <div class="user-info">
                                    <div class="user-info-item">
                                        <div class="info-des">{{ $t('user.label_account') }}:</div>
                                            <div v-if="userPhone" class="info-value">{{ userPhone }}</div>
                                            <router-link v-else class="user-link"  to="/user/phone">{{ $t('ga.status_unset') }}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col d-none d-md-block text-end">
                            <img class="header-right-img" src="@/assets/images/v4.1/user-home-bg.png" />
                        </div>
                    </div>
                </div>
            </section>

            <!-- <div class="page-part">
                <div class="container">
                    <div class="row top-icons">
                        <div class="col">
                            <div>{{ profile.realName }}</div>
                            <div>
                                {{ $t('user.balance_amount') }} <b>{{ balance }}</b> {{ currencySymbol }}
                            </div>
                        </div>
                        <div class="col text-right">
                            <router-link to="/finance/deposit" class="btn btn-primary">{{ $t('user.deposit') }}</router-link>
                            <router-link to="/finance/withdraw" class="btn btn-default">{{ $t('user.withdraw') }}</router-link>
                        </div>
                    </div>
                </div>
            </div> -->

            <section class="w-100">
                <!--
                        TODO:
                        Do we need to display notices.

                        <notice-list-component />
                    -->
                <settings-panel-component v-bind:profile="profile" />
            </section>
        </template>
        <div v-else>
            <loading-indicator />
        </div>
    </section>
</template>

<style scoped>
.hidden-input{
    display: none;
}
.top-header {
    color: var(--top-header-text);
    background: var(--top-header-bg);
    overflow: hidden;
}

.top-header::v-deep .spinner-border {
    border-width: 2px;
    opacity: 0.2;
}

.top-header .svg-icon {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentColor;
}

.top-header .vip-label {
    font-size: 1rem;
    color: var(--text-vip);
    font-weight: 400;
    border: 1px solid var(--text-vip);
    border-radius: 0.25rem;
    margin-left: 1rem;
    padding: 0 0.25rem;
}

.top-header .container .header-right-img {
    max-width: 20rem;
    width: 80%;
    height: auto;
    margin: auto;
}
.top-header .align-items-center img {
    width: 80px;   
    height: 80px;
    margin-bottom: 0;
    margin: 0;
    margin-right: 12px;
}
.icon-warning {
    height: 1rem;
    width: 1rem;
    margin-top: -3px;
}
.info-des:hover .icon-warning {
    fill: var(--primary-base);
    stroke: var(--primary-base);
}
.btn-checkin {
    margin-left: 10px;
    background: #1673f9;
}
.btn-checkin.btn-primary:hover, .btn-checkin.btn-primary:not(:disabled):not(.disabled):active {
    background: #1673f9;
    opacity: 0.8;
}
.btn-checkin:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    opacity: 0.8;
    background: #1673f9;
}
.verifyid-link {
    margin-bottom: 0;
}
.help-block {
    font-size: 13px;
}
.info-des {
    font-size: 16px;
    color: #fff;
}
.info-value {
    font-size: 14px;
    opacity: .6;
}
@media(max-width:1024px){
    .info-des {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        margin-right: 8px;
    }
    .info-value {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        line-height: 22px;
    }
    .settings-page {
        padding-bottom: 50px;
    }
}
</style>

<script>
import '@/assets/svg/icon-warning-sign.svg';
import '@/assets/svg/icon-checked.svg';
import '@/assets/svg/icon-arrow-right.svg';

import NoticeListComponent from './Components/Notices.vue';
import SettingsPanelComponent from './Components/SettingsPanel-4.1.vue';
import { setProfile } from 'utilities/helper';
import compressed from 'utilities/Compressed';
export default {
    components: { NoticeListComponent, SettingsPanelComponent },
    data() {
        return {
            profile: null,
            id_status: NaN,
            hasChecked:true
        };
    },

    created() {
        this.getProfileAsync().catch((err) => {
            console.error(`ERROR: ${err}`);
        });
        this.getCheckIn()
    },
    computed:{
        userPhone() {
            return this.profile.phone?this.profile.phone:null
        },
    },

    methods: {
        triggerFileInput() {
            this.$refs.avatarInput.click();
        },
        uploadAvatar:async function(event) {
            const fileInput = this.$refs.avatarInput;
            const file = fileInput.files[0];
            if (file.size > 10 * 1024 * 1024) {
                fileInput.value = '';
                $.top_error(this.$t('idverification.err_too_big_file', { fileSize: Math.ceil(file.size / 1024.0) }));
                return false;
            }
            if (file) {
                try {
                    // No need to compress the photo if its size is smaller than 200k
                    let currentSize = parseInt(file['size']);
                    const formData = new FormData();
                    if (currentSize > 200 * 1024) {
                        const updated = await compressed.compressImg(file);
                        formData.append("avatar", updated);
                    }else{
                        formData.append("avatar", file);
                    }
                    $.callPostApi(this, '/api/v1/account/avatar',formData).then((response) => {
                        // update avatarUrl
                        if(response.errcode == 0){
                            this.$set(this.profile, "avatarUrl", response.data.AvatarUrl);
                            $.top_alert(this.$t('general.avatar_uploaded'));
                        }else{
                            $.top_error(response.errmsg);
                        }
                    }).catch((error) => {
                        console.error("Avatar upload failed:", error);
                        $.top_error(this.$t('general.avatar_upload_failed'));
                    });
                } catch (err) {
                    // The error could be ignored.
                    console.error(`Failed to compress avatar: ${err}`);
                }
            }
        },
        getCheckIn() {
            console.log(this.sysconfig.checkinEnabled)
            if(this.sysconfig.checkinEnabled) {
                $.callGetApi(this, '/api/v1/checkin').then((res) => {
                    const {errcode,data} = res
                    if(errcode === 0 && data) {
                        this.hasChecked = data.checkedIn
                    }
                });
            }
        },
        postCheckIn(){
            if(this.hasChecked === false) {
                $.callPostApi(this, '/api/v1/checkin').then((res) => {
                    const {errcode,data} = res
                    if(errcode === 0 && data) {
                        $.top_alert(this.$t('coin_treasure.sign_in_successfully'));
                        this.hasChecked = true
                    } else {
                        this.hasChecked = false
                    }
                });
            }
        },
        getProfileAsync: async function () {
            const self = this;
            const json = await $.callPostApi(self, '/api/v1/account/profile');

            // Could be undefined if authentication is required.
            if (json && json.errcode === 0 && json.data) {
                setProfile(json.data);
                let profile = Object.freeze(json.data);

                // Read ID verification status
                const resp = await $.callGetApi(self, '/api/v1/identityverification');
                if (resp) {
                    if (typeof resp.data !== 'number' || resp.data < 0) throw new Error('Invalid id-verification status response.');
                    self.id_status = resp.data;
                };
                self.profile = JSON.parse(JSON.stringify(profile));
            }
        }
    }
};
</script>