<template>
    <div>
        <div class="settings-card">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/user/balance">
                                {{ $t('home.footer_assets') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/exchange/orders">
                                {{ $t('home.header_exchange_orders') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/futures/openorders">
                                {{ $t('home.header_futures_orders') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="sysconfig.bOptionEnabled" class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/boption/orders">{{ $t('home.header_boption_orders') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="sysconfig.bOptionEnabled" class="col-12 col-md-6">
                        <div class="settings-item">
                            <router-link to="/user/ga">{{ $t('ga.title') }}
                                <svg viewBox="0 0 256 256">
                                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class=" settings-card">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h1>{{ $t('user.label_security') }}</h1>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/notices">{{ $t('notices.notice_title') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/reset">
                                        {{ $t('user.label_password') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row" v-if="sysconfig.requiresWithdrawPassword === true">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/changewithdrawpwd">
                                        {{ $t('user.label_financial_password') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/bankaccounts">
                                        {{ $t('user.label_bankaccount') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="row">
                            <div class="col">
                                <div class="settings-item">
                                    <router-link to="/user/signout">
                                        {{ $t('user.label_signout') }}
                                        <svg viewBox="0 0 256 256">
                                            <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default { props: ['profile'] };
</script>
<style scoped>
.settings-card:first-child {
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
}
.settings-card h1 {
    margin-top: 0;
}
.settings-item {
    border: 1px solid #f9f9f9;
    background: #fcfcfc;
    border-radius: 10px;
    padding: 0 20px;
    margin-bottom: 10px;
}
.settings-item:hover {
    background-color: #f9f9f9;
}
.settings-item a:hover {
    background: none;
    color: #1673f9;
}
</style>