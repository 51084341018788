<template>
    <i v-if="symbol && symbol.metadata" class="d-flex token-icon">
        <div v-if="symbol.metadata.svgIcon">
            <svg>
                <use :xlink:href="'#symicon-' + symbol.metadata.id"></use>
            </svg>
        </div>
        <template v-else>
            <div v-if="is_builtin_png_icon" :class="'png-icon-' + token"></div>
            <svg v-else-if="builtin_svg_icon">
                <use :xlink:href="'/dist/svg/icons.svg#' + builtin_svg_icon"></use>
            </svg>
        </template>
    </i>
</template>

<script>
import '../../assets/coins/svg/Avalanche.svg';
import '../../assets/coins/svg/Binance.svg';
import '../../assets/coins/svg/Bitcoin.svg';
import '../../assets/coins/svg/Caradano.svg';
import '../../assets/coins/svg/chainlink.svg';
import '../../assets/coins/svg/cosmos.svg';
import '../../assets/coins/svg/Crypto.svg';
import '../../assets/coins/svg/Daicoin.svg';
import '../../assets/coins/svg/Dogecoin.svg';
import '../../assets/coins/svg/DOT.svg';
import '../../assets/coins/svg/Ethereum.svg';
import '../../assets/coins/svg/Litecoin.svg';
import '../../assets/coins/svg/polygon.svg';
import '../../assets/coins/svg/ShibaInu.svg';
import '../../assets/coins/svg/SOL.svg';
import '../../assets/coins/svg/TerraClassic.svg';
import '../../assets/coins/svg/Tether.svg';
import '../../assets/coins/svg/uniswap.svg';
import '../../assets/coins/svg/USDC.svg';
import '../../assets/coins/svg/XPR.svg';
import '../../assets/coins/svg/Ethereum.svg';
import '../../assets/coins/svg/placeholder.svg';

const svg_icons = {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    USDT: 'Tether',
    SOL: 'SOL',
    ADA: 'Caradano',
    XRP: 'XPR',
    DOT: 'DOT',
    AVAX: 'Avalanche',
    DOGE: 'Dogecoin',
    LTC: 'Litecoin',
    UNI: 'uniswap',
    LINK: 'chainlink',
    BNB: 'Binance',
    ATOM: 'cosmos',
    CRO: 'Crypto',
    DAI: 'Daicoin',
    MATIC: 'polygon',
    SHIB: 'ShibaInu',
    LUNC: 'TerraClassic',
    USDC: 'USDC'
};

const png_icons = {
    ADA: true,
    AVAX: true,
    AXS: true,
    DOT: true,
    EMT: true,
    GOT: true,
    HHS: true,
    MCH: true,
    NEH: true,
    SOL: true,
    TEC: true,
    TML: true,
    UNI: true
};

export default {
    props: ['symbol'],

    data() {
        return {
            // The name of the token.
            token: null
        };
    },

    mounted() {
        this.token = this.symbol ? this.symbol.metadata.baseSymbol || this.symbol.metadata.name : '';
    },

    computed: {
        builtin_svg_icon() {
            return svg_icons[this.token];
        },
        is_builtin_png_icon() {
            return png_icons[this.token] === true;
        }
    }
};
</script>
<style scoped>
.png-icon-ADA {
    background-image: url('@/assets/coins/png/ADA.png');
}
.png-icon-AVAX {
    background-image: url('@/assets/coins/png/AVAX.png');
}
.png-icon-AXS {
    background-image: url('@/assets/coins/png/AXS.png');
}
.png-icon-DOT {
    background-image: url('@/assets/coins/png/DOT.png');
}
.png-icon-EMT {
    background-image: url('@/assets/coins/png/EMT.png');
}
.png-icon-GOT {
    background-image: url('@/assets/coins/png/GOT.png');
}
.png-icon-HHS {
    background-image: url('@/assets/coins/png/HHS.png');
}
.png-icon-MCH {
    background-image: url('@/assets/coins/png/MCH.png');
}
.png-icon-NEH {
    background-image: url('@/assets/coins/png/NEH.png');
}
.png-icon-SOL {
    background-image: url('@/assets/coins/png/SOL.png');
}
.png-icon-TEC {
    background-image: url('@/assets/coins/png/TEC.png');
}
.png-icon-TML {
    background-image: url('@/assets/coins/png/TML.png');
}
.png-icon-UNI {
    background-image: url('@/assets/coins/png/UNI.png');
}
</style>