<template>
    <div class="login container">
        <div class="login-inner">
            <form action="javascript:void(0);" method="post" class="login-form" @submit="do_submit">
                <h1>{{ $t('reset_password.title') }}</h1>
                <div class="form-group">
                    <label>{{ $t('reset_password.label_account') }}</label>
                    <div class="d-flex">
                        <region-selector v-bind:regions="regions" :regionCode="regionCode" @change="onRegionCodeChanged" />
                        <div class="flex-fill">
                            <input
                                name="Name"
                                ref="accountInput"
                                v-model="account"
                                pattern="\d*"
                                type="text"
                                class="form-control phone round-right"
                                data-val="true"
                                data-val-required="*"
                                :placeholder="$t('reset_password.label_account')" />
                        </div>
                    </div>
                </div>
                <div class="form-group" :style="{ opacity: 0, width: 0,height: 0 ,margin:0 ,padding:0} " >
                    <!--  This hidden input is used to solve the input flickering problem of Apple mobile phones -->
                    <input type="text" style="opacity: 0;width: 0;height: 0" />
                </div>
                <div class="form-group">
                    <label>{{ $t('reset_password.label_password') }}</label>
                        <div class="password-box">
                            <input
                                name="Password"
                                v-model="password"
                                :type="showPassword?'text':'password'"
                                autocomplete="new-password"
                                class="form-control"
                                data-val="true"
                                data-val-required="*"
                                :placeholder="$t('reset_password.label_password')"
                            />
                            <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                                <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                            </svg>
                            <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                                <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                            </svg>
                        </div>
                </div>
                <div class="form-group">
                    <label>{{ $t('reset_password.label_confirm_password') }}</label>
                        <div class="password-box">
                            <input
                                name="ConfirmPassword"
                                v-model="confirmPassword"
                                :type="showConfirmPassword?'text':'password'"
                                autocomplete="new-password"
                                class="form-control"
                                data-val="true"
                                data-val-required="*"
                                data-val-equalto-other="Password"
                                data-val-equalto="*"
                                :placeholder="$t('reset_password.label_confirm_password')"
                            />
                            <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                                <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                            </svg>
                            <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                                <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                            </svg>
                        </div>
                </div>
                <sms-verification :regionCode="regionCode" :account="account" action="reset-password" />
                <div class="form-group">
                    <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                        {{ $t('reset_password.title') }}
                    </button>
                </div>
                <div class="col text-right">
                    <router-link to="/user/create">{{ $t('login.free_register') }}</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
    .container {
        padding: 0;
        border-radius: 0;
        box-shadow: 0px 2px 30px 0px rgba(0,0,0,0.05);
        background-color: #fff;
    }
    .container .login-inner {
        margin: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
        padding: 2.5rem 3.75rem;
    }
    .login-inner .login-form h1 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #000922;
        line-height: 32px;
        text-align: left;
        padding: 1rem 0;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
    }
    .login-form .form-group {
        margin-top: 1rem;
    }
    .login-form .form-group:nth-child(2) {
        margin-bottom: 0;
    }
    .form-group label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #40475A;
        line-height: 20px;
        margin-bottom: .5rem;
    }
    .form-group .form-control {
        border-radius: 0;
        height: 44px;
    }
    .form-group .form-control:focus {
        border: 1px solid var(--primary-base);
    }
    .password-box {
        position: relative;
    }
    .password-icon {
        position: absolute;
        right: 15px;
        bottom: 50%;
        transform: translateY(50%);
        cursor: pointer;
    }
    .login-form button.btn-submit {
        margin-top: 2.5rem;
    }
    @media(max-width: 768px) {
        .login.container {
            width: 100%;
            height: 100%;
            margin: 0;
            overflow: hidden;
            flex: 1;
            max-width: none;
        }
        .login.container .login-inner {
            height: 100%;
            width: 70%;
            padding: 12px 21px;
            max-width: none;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
        .login.container .login-inner .login-form {
            width: 100%;
        }
    }
    @media (max-width: 576px) {
        .login.container .login-inner {
            width: 100%;
        }
    }
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
    components: { RegionSelector },
    props: ['regions'],
    data() {
        return {
            account: null,
            regionCode: null,
            password: null,
            confirmPassword: null,

            showInputs: true,

            submitting: false,
            completed: false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        do_submit: function (e) {
            const frm = $(e.target);
            const self = this;
            if (self.password !== self.confirmPassword) {
                $.top_error(self.$t("withdraw_password.err_reset_password"));
                return false;
            }
            if (frm.valid()) {
                this.submitting = true;

                const data = frm.serialize();
                $.callPostApi(self, '/api/v1/account/resetpassword', data)
                    .then((json) => {
                        if (json && json.errcode === 0) {
                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                self.$router.push('/user');
                            }, 500);
                        } else {
                            $.top_error(json.errmsg);
                        }
                    })
                    .catch(function (err) {
                        $.top_error(self.$t('general.operation_error'));
                    })
                    .then(() => {
                        self.submitting = false;
                    });
            }
        },

        focusAccount: function () {
            this.$refs.accountInput.focus();
        },

        onRegionCodeChanged: function (val) {
            this.regionCode = val;
        }
    }
};
</script>