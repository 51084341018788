<template>
    <div class="mod bg-content order-options">
        <div class="mod-head bg-title text-label d-flex justify-content-between">
            <div>{{ $t('boption.label_order_title') }}</div>
            <button type="button" class="close" @click="onCloseOrderOptions"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="mod-body text-content">
            <div class="row flex-column">
                <div class="col text-label">
                    {{ $t('boption.label_order_amount') }}
                </div>
                <!-- <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="toggle-use-coupons" v-model="boptionOrder.useCoupons" />
                        <label class="custom-control-label" for="toggle-use-coupons">
                            {{ $t('boption.label_order_use_coupons') }}
                            <span v-if="!isNaN(numCoupons)">({{ numCoupons }}) </span>
                        </label>
                    </div>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" id="toggle-custom-amount" v-model="useCustomAmount" :disabled="boptionOrder.useCoupons" />
                        <label class="custom-control-label" for="toggle-custom-amount">{{ $t('boption.label_custom_amount') }}</label>
                    </div>
                </div> -->
                <!-- <div class="col" v-if="!useCustomAmount && !boptionOrder.useCoupons"> -->
                <div class="col">
                    <div class="row">
                        <div class="col-4" v-for="amt in boptionConfig.supportedAmounts" :key="amt">
                            <div class="inline-radio">
                                <input type="radio" name="amount" :id="'order_amount' + amt" :value="amt" v-model="boptionOrder.amount" />
                                <label :for="'order_amount' + amt">{{ formatAmount(amt) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col" v-if="useCustomAmount && !boptionOrder.useCoupons">
                    <div class="input-group">
                        <input type="number" ref="customAmountInput" name="custom_amount" v-model="boptionOrder.amount" class="form-control" />
                        <div class="input-group-append">
                            <div class="input-group-text">{{ currencySymbol }}</div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="row flex-column">
                <div class="col text-label">{{ $t('boption.label_order_duration') }}</div>
                <div class="col">
                    <div class="row">
                        <div class="col-4" v-for="(val, index) in boptionConfig.durations" :key="index">
                            <div class="inline-radio">
                                <input type="radio" name="duration" :id="'order_duration' + val.duration" :value="val.duration" v-model="duration" />
                                <label :for="'order_duration' + val.duration">{{ val.duration }}s</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row flex-column">
                <div class="col text-label">{{ $t('boption.label_order_profit_rate') }}</div>
                <div class="col profit-rate color-down">{{ boptionOrder.profitRate }}</div>
            </div>

            <div class="row">
                <div class="col">
                    <button class="btn-submit bg-up" @click="triggerCreateOrder(true)">{{ $t('boption.label_buy_up') }} <i class="fa fa-arrow-up"></i></button>
                    <button class="btn-submit bg-down" @click="triggerCreateOrder(false)">{{ $t('boption.label_buy_down') }} <i class="fa fa-arrow-down"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.bg-title.text-label {
    background: #1D2027;
    color: #CFD3E9;
}
.col-4 .inline-radio label {
    background: #1D2027;
    color: #6A6E79;
}
.inline-radio input[type=radio]:checked + label {
    background-color: var(--primary-base);
}
.order-options .mod-head button.close{
    background:#1D2027;
    color: #fff;
}
</style>
<script>
export default {
    props: ['boptionConfig', 'boptionOrder'],

    data() {
        return {
            // The duration of a new order.
            duration: null

            // Use customized amount.
            // useCustomAmount: false,

            // The number of active coupons
            // numCoupons: NaN
        };
    },

    created() {
        this.duration = this.boptionOrder.duration;

        // Get the number of active coupons.
        // this.getNumActiveCouponsAsync();
    },

    watch: {
        duration: function () {
            this.boptionOrder.changeDuration(this.duration);
        }

        // useCustomAmount: function () {
        //     if (this.useCustomAmount) {
        //         const self = this;
        //         Vue.nextTick(() => {
        //             $(self.$refs.customAmountInput).focus();
        //         });
        //     }
        // }
    },

    methods: {
        triggerCreateOrder: function (direction) {
            if (this.boptionOrder.useCoupons === false) {
                const amt = this.boptionOrder.amount * 1;
                if (!Number.isInteger(amt) || amt < 1) {
                    if (this.useCustomAmount) {
                        $(this.$refs.customAmountInput).focus();
                    }
                    return false;
                }
            }

            this.$emit('create-order', direction);
        },

        onCloseOrderOptions: function () {
            this.$emit('close-options');
        },

        formatAmount: function (amt) {
            if (amt >= 1000) {
                if (amt % 1000 === 0) {
                    return (amt / 1000).toLocaleString('en') + 'k';
                }
                return amt.toLocaleString('en');
            }
            return amt;
        }

        // getNumActiveCouponsAsync: async function () {
        //     const json = await $.callPostApi(self, '/api/v1/coupon/activecount');
        //     if (json && json.errcode === 0 && typeof json.data === 'number') {
        //         console.log(`## ${json.data} active coupons.`);
        //         this.numCoupons = json.data;
        //     }
        // }
    }
};
</script>