<template>
    <section class="page startup-page support-iframe">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title-box">
                            <div class="col startup-title">
                                <h1>{{ $t('general.startup') }}</h1>
                                <div class="tips" v-if="context.profile && context.profile.groups && context.profile.groups.length">
                                    {{sysconfig.groups[$i18n.locale.toLowerCase()][context.profile.groups[0]]}}
                                </div>
                            </div>
                            <div class="title-des media-display">{{ $t('home.guide_verify_identity_long') }}</div>
                            <div class="step-img-box media-display">
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupFirst.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupSecond.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupThird.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/images/v4.2/startupLogo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">

            <ul class="page-top-nav">
                <li class="top-nav-item" :class="{ active: !ongoing_tokens() }">
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li>
                <li class="top-nav-item" :class="{ active: ongoing_tokens() }">
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li  class="top-nav-item">
                    <router-link to="/startup/orders">{{ $t('startup.title_orders') }}</router-link>
                </li>
            </ul>

            <!-- Display a list of tokens -->
            <token-list :ongoing_tokens="ongoing_tokens()" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>
<style scoped>
.page-top {
    background: linear-gradient(to bottom,#EFF5FF,#E4EFFF);
}
.page-top .container {
    margin: 43px auto 21px;
}
.page-top .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-top .col.startup-title{
    justify-content: flex-start;
}
.col .title-box {
    flex: 1;
}
.line {
    margin: 0;
    width: 27px;
    background: #7F8490;
}
.media-display {
    visibility: hidden;
}
.page-top .col img {
    width: 177px;
    height: 150px;
}
.title-box .step-img-box {
    display: flex;
    width: 100%;
    align-items: center;
}
.step-img-box .line {
    margin-top: -13px;
}
.title-box .step-img-box .step-img {
    width: 24px;
    height: 24px;
}
.img-box {
    text-align: center;
}
.img-box .step-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: #7F8490;
    line-height: 14px;
    word-break: keep-all;
}
.page-top .col h1 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #000922;
    line-height: 50px;
}
.page-top-nav {
    margin: 40px 0 30px;
    border: none;
}
.page-top-nav .top-nav-item {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    margin-right: 20px;
}
.page-top-nav .top-nav-item a {
    color: #000;
    font-size: 18px;
}
.page-top-nav .top-nav-item.active {
    color: #1673f9;
    border-bottom: 2px solid #1673f9;
}
.page-top-nav li.active a {
    color: #1673f9;
    font-size: 20px;
    font-weight: 600;
}
.startup-title .tips{
    color: #11CF8B;
    background: url(@/assets/images/startup.png) no-repeat;
    font-weight: bold;
    padding: 0.25rem 1rem;
    border-radius: 2rem;
    background-size: cover;
    margin-left: 1rem;
    font-size: 1.5rem;
}
@media (max-width: 768px) {
    .page {
        background: #FAFAFA;
    }
    .step-img {
        height: 24px;
    }
    .page-top,.pro_layout {
        background-color: #fff;
    }
    .title-box h1 {
        margin: 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #000922;
        line-height: 24px;
    }
    .title-des {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #1673F9;
        line-height: 16px;
        margin: 8px 0 22px;
    }
    .media-display {
        visibility: visible;
    }
    .page-top-nav {
        margin: 0 0 23px;
    }
    .page-top-nav .active {
        border-bottom: 2px solid #1673F9;
    }
    .page-top .col img {
        transform: scale(.93);
    }
    .img-box .step-des {
        word-break: break-all;
    }
    .page-top .col h1{
        font-size: 18px;
    }
    .page-top .col.startup-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .startup-title .tips{
        font-size: 12px;
        margin-left: 0;
    }
}
</style>


<script>
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import TokenList from './Components/TokenList.vue';
import { getAppContext } from 'utilities/helper';
export default {
    components: { ExtResourceIFrame, TokenList },

    data() {
        return {
            // Display on-going  or forecast tokens?
            context: null
        };
    },
    created() {
        this.context = getAppContext();
    },
    methods: {
        ongoing_tokens() {
            const queryType = this.$route.query.type;
            
            if (queryType === 'ongoing') {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>