<template>
    <section class="w-100">
        <section class="notice">
            <div class="container">
                <div class="row flex-nowrap scroll-notices">
                    <div class="col">
                        <div class="d-flex">
                            <!-- <div class="notice-pre px-2 d-none d-md-block">{{ $t('notices.notice_title') }}</div>
                        <div class="notice-pre-icon d-none d-md-block">
                            <svg viewBox="0 0 100 100">
                                <path d="M0,100 V0,0 H0,40Z"></path>
                            </svg>
                        </div> -->
                            <div class="flex-fill notice-items">
                                <div class="scroll-notice" v-for="entity in notices" :key="entity.metadata.id" :class="entity.className">
                                    <div>
                                        <router-link :to="'/notice/' + entity.metadata.id" rel="noopener noreferrer nofollow">
                                            <div v-html="entity.metadata.title"></div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="notice-more text-nowrap px-3">
                            <router-link to="/notices">
                                {{ $t('home.label_more') }}
                                <svg width="1em" height="1em" viewBox="0 0 1024 1024">
                                    <path d="M128 469.333333h604.586667l-152.746667-153.173333L640 256l256 256-256 256-60.16-60.16L732.586667 554.666667H128z"></path>
                                </svg>
                            </router-link>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <popup-notice-component v-if="popupNotice" :notice="popupNotice" />
    </section>
</template>

<script>
import '@/assets/svg/icon-notice.svg';

import { getAppContext, scanPopupNotices } from '@/assets/js/helper';
import PopupNoticeComponent from './_PopupNotice.vue';

function WrappedNotice(entity) {
    this.metadata = entity;
    this.className = '';
}

export default {
    components: { PopupNoticeComponent },
    props: ['promptedNotices'],

    data() {
        return {
            notices: [],
            timerId: 0,
            currentPos: -1,

            // The notice to be poped up.
            popupNotice: null,

            // The app context
            context: null
        };
    },

    watch: {
        'context.profile': function (to, from) {
            // only display popup notice after the user logged in.
            if (to) {
                this.showPopup();
            }
        }
    },

    created() {
        const output = [];
        $(this.promptedNotices).each((index, entity) => {
            output.push(new WrappedNotice(entity));
        });
        this.notices = output;

        this.showNext();
        if (this.notices.length > 1) {
            const self = this;
            this.timerId = setInterval(() => {
                self.showNext();
            }, 5000);
        }

        // Is the in the log-in state?
        const context = getAppContext();
        this.context = context;
        this.showPopup();
    },

    beforeDestroy() {
        clearInterval(this.timerId);
    },

    methods: {
        showNext: function () {
            // console.log(`#### showing next. current=${this.currentPos}`);
            const current = this.currentPos++;
            if (current >= 0) {
                this.notices[current].className = `away`;
            }

            let now = this.currentPos;
            if (now >= this.notices.length) {
                this.currentPos = 0;
                now = 0;
            }
            if (now !== current) {
                this.notices[now].className = 'show';
            }
        },

        showPopup: function () {
            if (!this.popupNotice && this.context.profile) {
                this.popupNotice = scanPopupNotices(this.promptedNotices);
            }
        }
    }
};
</script>